import { FC } from "react"
import { NextButton, NextButtonDefaultContainer } from "../../shared/NextButton"
import { AspectRatio, Box, Flex, Heading, Image, Text } from "@chakra-ui/react"
import type { NextPageCb } from "../../../hooks/useNextQuiz"
import {
  AttributedString,
  AttributedStringType,
  useAttributedStringLength,
} from "../../shared/AttributedString"
import { useIsLoviTheme } from "~/theme/themeProvider"

const Video: FC<{ src: string; image: string }> = ({ src, image }) => (
  <video muted playsInline autoPlay src={src} poster={image}>
    <img src={image} alt="" />
  </video>
)

export const SplashPage: FC<{
  title: AttributedStringType
  description: string | undefined
  image: string | undefined
  imageWidth: number
  imageHeight: number
  video: string | undefined
  next: NextPageCb
}> = ({ title, description, image, video, imageWidth, imageHeight, next }) => {
  const len = useAttributedStringLength(title)
  let media = <></>
  if (video) {
    media = <Video src={video} image={image ?? ""} />
  } else if (image) {
    media = <Image src={image} objectFit="cover" />
  }
  const isLoviTheme = useIsLoviTheme()
  return (
    <Flex direction="column" height="100%">
      <Box height="100%" paddingX={6} paddingY={4}>
        <Flex direction="column" height="100%" justifyContent="center">
          {media && (
            <AspectRatio ratio={imageWidth / imageHeight} marginBottom={6} borderRadius="2xl">
              {media}
            </AspectRatio>
          )}
          {title && (
            <Heading
              paddingX={4}
              textAlign="center"
              size={len >= 40 ? "Header/Primary" : "quoteScreen1"}
            >
              <AttributedString>{title}</AttributedString>
            </Heading>
          )}
          {description && (
            <Text
              paddingX={4}
              marginTop={4}
              textAlign="center"
              fontSize="md"
              color={isLoviTheme ? "Base/basePrimary" : "Base/baseSecondary"}
            >
              {description}
            </Text>
          )}
        </Flex>
      </Box>
      <NextButtonDefaultContainer>
        <NextButton onClick={next} />
      </NextButtonDefaultContainer>
    </Flex>
  )
}
