import { FC, useCallback, useMemo } from "react"
import { Box, Flex, Heading, Spinner } from "@chakra-ui/react"
import type { SlideLoaderScreen_Slide } from "~/generated/interview_service"
import type { NextPageCb } from "~/hooks/useNextQuiz"
import { AttributedStringType, AttributedString } from "~/components/shared/AttributedString"
import { PresentationImage, PresentationImageType } from "~/components/shared/PresentationImage"
import { FramerBox } from "~/components/shared/FramerBox"

const Slide: FC<{
  title?: AttributedStringType
  image?: PresentationImageType
  duration_ms?: number
}> = ({ title, image }) => {
  return (
    <Flex direction="column" minW="full" maxW="full" paddingX={6} gap={2}>
      {image && <PresentationImage source={image} />}

      {title && (
        <Heading size="MediumHeaderSecondary" textAlign="center">
          <AttributedString>{title}</AttributedString>
        </Heading>
      )}
    </Flex>
  )
}

const computeTime = (totalTime: number, timePosition: number) => timePosition / totalTime

const slideAnimation = (slidesDuration: number[], transitionDuration: number) => {
  const halfTransition = transitionDuration / 2
  const totalDuration = slidesDuration.reduce((acc, curr) => acc + curr, 0)

  const x = slidesDuration.flatMap((_, i) => {
    const posX = `${i * -100}%`
    return [posX, posX]
  })

  const slidesTimes = slidesDuration.reduce((acc, cur) => {
    const prevValue = acc[acc.length - 1]
    return acc.concat([cur + (prevValue ?? 0)])
  }, [] as number[])

  const times = slidesTimes.map((time) => [
    computeTime(totalDuration, time - halfTransition),
    computeTime(totalDuration, time + halfTransition),
  ])
  times[times.length - 1] = [1]
  times.unshift([0])

  const transition = {
    duration: totalDuration / 1000, // seconds
    times: times.flat(), // keyframes times
    ease: "easeInOut",
    repeat: 0,
  }

  return { animate: { x }, transition }
}

const useSlideAnimation = (slidesDuration: number[], transitionDuration: number) =>
  useMemo(
    () => slideAnimation(slidesDuration, transitionDuration),
    [slidesDuration, transitionDuration]
  )

const TRANSITION_DURATION = 200

const Slides: FC<{
  slides: SlideLoaderScreen_Slide[]
  onFinish: () => void
}> = ({ slides, onFinish }) => {
  const slidesDuration = useMemo(() => slides.map(({ duration_ms }) => duration_ms), [slides])
  const { animate, transition } = useSlideAnimation(slidesDuration, TRANSITION_DURATION)
  const onFinishFn = useCallback(() => {
    onFinish()
  }, [onFinish])
  return (
    <Box w="full" overflow="hidden">
      <FramerBox
        display="flex"
        flexDirection="row"
        w="full"
        animate={animate}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        /* @ts-ignore */
        transition={transition}
        onAnimationComplete={onFinishFn}
      >
        {slides.map((slide, i) => (
          <Slide key={i} {...slide} />
        ))}
      </FramerBox>
    </Box>
  )
}

const LoadingIndicator: FC<{ title?: string }> = ({ title }) => (
  <Flex gap={2} direction="column" color="Base/baseSecondary" w="full" alignItems="center">
    <Spinner />
    {title && <Box textStyle="MediumSubtitleSecondary">{title}</Box>}
  </Flex>
)

export const SlideLoaderPage: FC<{
  processTitle: string
  slides: SlideLoaderScreen_Slide[]
  next: NextPageCb
}> = ({ processTitle, slides, next: onNext }) => (
  <Flex direction="column" alignItems="flex-start" gap="46px" marginTop="66px">
    <LoadingIndicator title={processTitle} />
    <Slides slides={slides} onFinish={onNext} />
  </Flex>
)
