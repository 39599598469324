import { createIcon } from "@chakra-ui/react"

export const PoraLogoIcon = createIcon({
  viewBox: "0 0 148 56",
  defaultProps: {
    width: "148px",
    height: "56px",
    fill: "none",
  },
  path: (
    <g fill="#fff">
      <path d="M46.373 34.223c.935.768 2.772 1.736 5.231 1.736 5.89 0 9.493-4.106 9.493-9.28 0-5.373-3.672-9.178-9.285-9.178-2.945 0-4.746 1.101-5.681 2.002v-1.702h-4.4V41.5h4.642v-7.277zm-.139-7.476c0-3.038 1.94-5.274 5.093-5.274 3.084 0 5.128 2.236 5.128 5.274 0 3.037-1.94 5.24-5.093 5.24-3.084 0-5.128-2.203-5.128-5.24zM62.42 26.78c0 5.274 3.88 9.18 9.665 9.18 5.717 0 9.7-4.006 9.7-9.247 0-5.274-3.845-9.213-9.7-9.213-5.577 0-9.665 3.973-9.665 9.28zm4.642-.034c0-3.004 1.975-5.207 5.023-5.207 2.98 0 5.059 2.237 5.059 5.207 0 2.971-1.975 5.174-5.024 5.174-2.98 0-5.058-2.17-5.058-5.174zM92.03 17.734c-1.906 0-2.945.601-3.603 1.502v-1.435h-4.643v17.858h4.642V24.777c0-1.535.624-2.37 2.426-2.37h1.177v-4.673zM92.634 29.817c0 3.705 2.737 6.075 6.443 6.075 2.148 0 3.396-.834 3.915-1.368v1.135h3.88V24.41c0-3.972-1.663-6.81-6.998-6.81-4.365 0-6.478 2.237-6.582 5.608h4.33c0-1.001.52-2.07 2.252-2.07 1.732 0 2.425 1.102 2.425 2.638v.901c-.485-.3-1.628-.734-3.29-.734-3.846 0-6.375 2.537-6.375 5.874zm4.365-.033c0-1.435 1.074-2.537 2.667-2.537 1.559 0 2.703 1.035 2.703 2.537s-1.144 2.57-2.668 2.57c-1.594 0-2.702-1.068-2.702-2.57z" />
      <path
        opacity=".5"
        d="M108.586 47.586c7.052-.758 12.573-2.86 18.082-5.161 18.067-7.558 23.693-17.404 10.557-25.515C116.799 4.336 70.004-1.138 34.818 6.128c-7.793 1.604-18.368 3.33-25.891 8.604-6.999 4.905-6.406 9.99-2.818 14.44 3.656 4.567 10.429 8.484 15.783 10.918 23.407 10.615 70.078 18.18 110.841 8.736.838-.187 1.695-.059 1.957.282.262.34-.139.762-.917.944-41.96 9.72-90.094 2.032-114.186-8.892-6.662-3.036-15.45-8.3-18.217-14.276-1.87-4.087-.969-8.5 5.095-12.782 7.989-5.617 19.22-7.528 27.493-9.235 36.325-7.503 84.704-1.888 105.76 11.101 14.141 8.716 8.194 19.301-11.236 27.425-5.923 2.493-11.982 4.726-19.637 5.548-.844.09-1.585-.14-1.606-.518-.082-.373.563-.752 1.347-.837z"
      />
    </g>
  ),
})

export const LoviLogoIcon = createIcon({
  viewBox: "0 0 62 32",
  defaultProps: {
    width: "62px",
    height: "32px",
    fill: "none",
    color: "#fff",
  },
  path: (
    <path
      d="M32.708 0c-1.718 0-3.124 1.406-3.124 3.163a3.133 3.133 0 003.124 3.124c1.757 0 3.163-1.406 3.163-3.124A3.15 3.15 0 0032.708 0zM18.2 27.205a16.452 16.452 0 01-5.607 3.404c-3.002 1.086-5.687 1.014-7.829.078-2.129-.93-3.588-2.664-4.176-4.62-.587-1.957-.302-4.159 1.083-5.903 1.39-1.752 3.695-2.807 6.703-2.907 3.515-.117 7.045 1.218 10.26 3.287.037-.09.075-.181.111-.274 1.51-3.82 1.66-9.608-1.841-18.116L20.166.812c3.716 9.029 3.816 15.809 1.86 20.755-.826 2.091-2.188 4.11-3.826 5.638zm-1.4-3.645c-1.542 1.848-3.504 3.042-5.408 3.731-2.314.838-4.047.674-5.215.163-1.18-.515-1.92-1.439-2.21-2.402-.29-.964-.13-1.943.467-2.695.59-.743 1.802-1.499 4.058-1.574 2.63-.087 5.504.956 8.308 2.777zm3.773 2.95c.119.108.237.219.355.33 2.64 2.496 6.474 4.7 10.799 5.097 4.414.405 9.147-1.097 13.367-5.685l-2.597-2.389c-3.51 3.818-7.18 4.86-10.448 4.56-3.358-.307-6.486-2.055-8.697-4.146a36.55 36.55 0 00-.784-.72c-.45.845-1.238 2.073-1.995 2.953zm4.635-9.487c0 4.233 3.111 7.858 7.858 7.858 4.811 0 7.922-3.625 7.922-7.858 0-4.234-3.11-7.858-7.922-7.858-4.747 0-7.858 3.624-7.858 7.858zm12.284 0c0 2.566-1.732 4.747-4.426 4.747-2.63 0-4.362-2.181-4.362-4.747s1.732-4.747 4.362-4.747c2.694 0 4.426 2.18 4.426 4.747zm3.025-7.473h3.753l4.105 9.942 4.17-9.942h3.752L49.53 24.496h-2.342L40.518 9.55zm17.661 0h3.496v14.946h-3.496V9.55z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
})
