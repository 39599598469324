import { FC, useState, useCallback, useMemo } from "react"
import { AnimatePresence } from "framer-motion"
import { Heading } from "@chakra-ui/react"
import { FramerBox } from "~/components/shared/FramerBox"

const INITIAL_SCALE = 0.95
const INITIAL_DISPLACEMENT = 10
const noop = () => undefined

export const getVariants = (delay: number) => ({
  initial: {
    opacity: 0,
    x: 0,
    y: -INITIAL_DISPLACEMENT,
    scale: INITIAL_SCALE,
  },
  show: { opacity: 1, x: 0, y: 0, scale: 1, transition: { duration: 0.2 } },
  exit: {
    opacity: 0,
    x: 0,
    y: INITIAL_DISPLACEMENT,
    scale: INITIAL_SCALE,
    transition: { duration: 0.2, delay },
  },
})
export const MessagesAnimation: FC<{
  messages: string[]
  delay?: number
  onComplete: () => void
}> = ({ messages, delay = 2, onComplete = noop }) => {
  const items = messages.length
  const [i, set] = useState(0)
  const variants = useMemo(() => getVariants(delay), [delay])

  const onAnimationComplete = useCallback(
    (i: number) => (stateName: string) => {
      if (stateName !== "exit" && i < items) {
        set(i + 1)
      }
    },
    [items]
  )
  const onCompleteFn = useCallback(
    (isLast: boolean) => () => {
      if (isLast) {
        onComplete()
      }
    },
    [onComplete]
  )
  return (
    <AnimatePresence mode="wait" onExitComplete={onCompleteFn(i >= items)}>
      {messages[i] && (
        <FramerBox
          key={i}
          variants={variants}
          initial="initial"
          animate="show"
          exit="exit"
          onAnimationComplete={onAnimationComplete(i)}
          display="flex"
          position="absolute"
          width="300px"
          padding={5}
          justifyContent="center"
          alignItems="center"
          userSelect="none"
          cursor="pointer"
        >
          <Heading size="Header/Secondary" textAlign="center" w="full">
            {messages[i]}
          </Heading>
        </FramerBox>
      )}
    </AnimatePresence>
  )
}
