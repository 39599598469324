import { createIcon } from "@chakra-ui/react"

export const Cross = createIcon({
  viewBox: "0 0 21 21",
  path: (
    <path
      d="M16.5 10.5h-12M10.5 16.5v-12"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  ),
})
