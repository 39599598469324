import { useCallback } from "react"
import { useNavigate, NavigateOptions } from "react-router-dom"
import { appendParams } from "./url"

export const useNavigateWithSearchParams = () => {
  const navigate = useNavigate()

  return useCallback(
    (url: string, args: Record<string, string> = {}, opts?: NavigateOptions): void => {
      const currentUrl = new URL(window.location.toString())
      const params = Object.fromEntries(currentUrl.searchParams.entries())
      const _newUrl = new URL(appendParams(url, { ...params, ...args }))
      //_newUrl.hash = currentUrl.hash
      const newUrl = _newUrl.toString()
      if (newUrl.startsWith(window.location.origin)) {
        navigate(newUrl.replace(window.location.origin, ""), opts)
      } else {
        window.location.href = newUrl
      }
    },
    [navigate]
  )
}

export const useNavigateBack = () => {
  const navigate = useNavigate()

  return useCallback((): void => {
    navigate(-1)
  }, [navigate])
}
