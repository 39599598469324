import { FC, ReactNode } from "react"
import {
  Box,
  BoxProps,
  ComponentWithAs,
  Flex,
  Heading,
  Icon,
  IconProps,
  Text,
  useDisclosure,
  useToken,
} from "@chakra-ui/react"
import { motion } from "framer-motion"

import { PresentationImage, PresentationImageType } from "~/components/shared/PresentationImage"
import { FramerBox } from "~/components/shared/FramerBox"
import { VFlex } from "~/components/shared/VFlex"
import { WellnessProfileScreen_Level } from "~/generated/interview_service"

import { SliderRainbow } from "./SliderRainbow"
import { Feather, Goal, Smile, Sun } from "./Icons"
import { Good, Low, Moderate } from "./TooltipIcons"

import TooltipTail from "./assets/TooltipTail.svg?react"
import HeaderLow from "./assets/HeaderLow.svg?react"
import HeaderModerate from "./assets/HeaderModerate.svg?react"
import HeaderGood from "./assets/HeaderGood.svg?react"

export { FakeNumbers } from "./FakeNumbers"

const LEVELS_DATA = {
  low: {
    color: "Other/Error",
    icon: Low,
    title: "Your skin needs intensive care",
    description: "Start your journey to healthier skin and achieve dream results.",
    header: {
      component: HeaderLow,
      color: "Other/Error",
    },
  },
  moderate: {
    color: "Other/Warning",
    icon: Moderate,
    title: "Your skin needs extra care",
    description:
      "Begin a healthy journey with expert tutorials, and personalized routines. Hydrate and rest!",
    header: {
      component: HeaderModerate,
      color: "Other/Warning",
    },
  },
  good: {
    color: "Other/Green",
    icon: Good,
    title: "Your skin needs essential care",
    description:
      "Maintain results and protect your skin from environmental exposure to preserve its vitality.",
    header: {
      component: HeaderGood,
      color: "Other/Green",
    },
  },
} as const
type Level = keyof typeof LEVELS_DATA
const mapLevelToSliderValue = (level: Level): number => {
  switch (level) {
    case "low":
      return 0.1
    case "moderate":
      return 0.5
    case "good":
      return 0.9
  }

  return 0.1
}
export const mapEnumToLevel = (levelEnum: WellnessProfileScreen_Level): Level => {
  switch (levelEnum) {
    case WellnessProfileScreen_Level.LOW:
      return "low"
    case WellnessProfileScreen_Level.MODERATE:
      return "moderate"
  }

  return "good"
}
const VARIANTS = {
  list: {
    visible: {
      transition: {
        delayChildren: 1,
        staggerChildren: 0.2,
        ease: "easeInOut",
      },
    },
    hidden: {},
  },

  item: {
    visible: { opacity: 1, x: "0%", transition: { duration: 0.5 } },
    hidden: { opacity: 0, x: "-50%" },
  },
}
const GoalsListItem: FC<{
  icon: ComponentWithAs<"svg", IconProps>
  title: string
  children: ReactNode
}> = ({ icon, title, children }) => (
  <motion.div variants={VARIANTS.item}>
    <VFlex gap={1} alignSelf="stretch">
      <Flex color="Base/baseSecondary" gap={0.5} alignItems="center">
        <Icon as={icon} w="16px" h="16px" />
        <Box textStyle={{ base: "Paragraph/Tertiary", lg: "Paragraph/Secondary" }}>{title}</Box>
      </Flex>
      <Box textStyle={{ base: "Subtitle/Tertiary", lg: "Subtitle/Secondary" }}>{children}</Box>
    </VFlex>
  </motion.div>
)

export const GoalsList: FC<{ data: Record<string, string> }> = ({ data }) => (
  <FramerBox
    initial="hidden"
    animate="visible"
    variants={VARIANTS.list}
    display="flex"
    flexDirection="column"
    flexGrow={1}
    gap={4}
    alignSelf="stretch"
  >
    {data["skin_type"] && (
      <GoalsListItem icon={Smile} title="Skin type">
        {data["skin_type"]}
      </GoalsListItem>
    )}
    {data["main_goal"] && (
      <GoalsListItem title="Main goal" icon={Goal}>
        {data["main_goal"]}
      </GoalsListItem>
    )}
    {data["skin_sensitivity"] && (
      <GoalsListItem title="Skin sensitivity" icon={Feather}>
        {data["skin_sensitivity"]}
      </GoalsListItem>
    )}
    {data["exposure_category"] && (
      <GoalsListItem title="Exposure category" icon={Sun}>
        {data["exposure_category"]}
      </GoalsListItem>
    )}
  </FramerBox>
)

const CollapsibleStringList: FC<{ issues?: string[] }> = ({ issues = [] }) => {
  const { isOpen, onToggle } = useDisclosure()
  const headIssues = issues.slice(0, 2)
  const tailIssues = issues.slice(2)

  if (tailIssues.length > 0 && !isOpen) {
    return (
      <>
        {headIssues.join(", ")}
        <Box
          textStyle="Subtitle/Tertiary"
          onClick={onToggle}
          cursor="pointer"
          color="Base/accentPrimary"
        >
          Show {tailIssues.length} more
        </Box>
      </>
    )
  }

  return <>{issues.join(", ")}</>
}

export const GoalsList2: FC<{ data: Record<string, string> }> = ({ data }) => (
  <FramerBox
    initial="hidden"
    animate="visible"
    variants={VARIANTS.list}
    display="flex"
    flexDirection="column"
    flexGrow={1}
    gap={4}
    alignSelf="stretch"
  >
    {data["skin_type"] && (
      <GoalsListItem icon={Smile} title="Skin type">
        {data["skin_type"]}
      </GoalsListItem>
    )}
    {data["skin_sensitivity"] && (
      <GoalsListItem title="Skin sensitivity" icon={Feather}>
        {data["skin_sensitivity"]}
      </GoalsListItem>
    )}
    {data["skin_concern"] && (
      <GoalsListItem title="Skin concern" icon={Goal}>
        <CollapsibleStringList issues={data["skin_concern"]?.split("\n")} />
      </GoalsListItem>
    )}
  </FramerBox>
)

export const HeaderExtended: FC<{ level: Level }> = ({ level }) => {
  const h = LEVELS_DATA[level].header
  const Component = h.component
  const [color] = useToken("colors", [h.color])
  return (
    <Heading textStyle="Header/Primary">
      You have
      <Text
        as={motion.span}
        initial={{ color: "#F2F5F7" }}
        animate={{ color }}
        transition="0.5s ease-in-out 0.25s"
        display="inline-block"
        position="absolute"
        marginTop="-10px"
        marginLeft="4px"
      >
        <Component />
      </Text>
      <br />
      Skin Wellness Level
    </Heading>
  )
}
const FakeTooltip: FC<{ color: string; level: number } & BoxProps> = ({
  color,
  level,
  ...props
}) => (
  <Box>
    <Box color={color} paddingLeft={`calc(${level * 100}% - 24px - 24px + 10px)`} marginX="24px">
      <TooltipTail />
    </Box>
    <Box
      borderRadius="brand24"
      padding={4}
      w="full"
      color="Base/neutralPrimary"
      bgColor={color}
      {...props}
    />
  </Box>
)

export const SliderWithTooltip: FC<{ level: Level }> = ({ level }) => {
  const { color, title, description, icon } = LEVELS_DATA[level]
  return (
    <Box>
      <FramerBox
        initial={{ x: "-50%", opacity: 0 }}
        animate={{ x: "0%", opacity: 1 }}
        /* @ts-ignore: Incorrect type from chakra.div */
        transition={{ delay: 0.5, duration: 0.25, ease: "easeInOut" }}
      >
        <SliderRainbow value={1 - mapLevelToSliderValue(level)} color={color} />
      </FramerBox>
      <FramerBox
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        /* @ts-ignore: Incorrect type from chakra.div */
        transition={{ delay: 0.5, duration: 0.5, ease: "easeInOut" }}
      >
        <FakeTooltip color={color} level={mapLevelToSliderValue(level)}>
          <Flex gap={4} alignItems="center">
            <Icon as={icon} w="56px" h="56px" color={color} />
            <VFlex gap={1}>
              <Box textStyle="Other/Price2">{title}</Box>
              <Box textStyle="Paragraph/Secondary">{description}</Box>
            </VFlex>
          </Flex>
        </FakeTooltip>
      </FramerBox>
    </Box>
  )
}

export const AgeImage: FC<{ source: PresentationImageType; title: string }> = ({
  source,
  title,
}) => (
  <VFlex gap={2} alignItems="center" justifyContent="start">
    <PresentationImage source={source} w="155" h="175" alt="" objectFit="scale-down" />
    <Box textStyle="Paragraph/Tertiary" color="Base/baseSecondary">
      {title}
    </Box>
  </VFlex>
)

export const AnimationWrapper: FC<{ children: ReactNode }> = (props) => (
  <FramerBox
    initial={{ x: "100%", opacity: 0 }}
    animate={{ x: "0%", opacity: 1 }}
    /* @ts-ignore: Incorrect type from chakra.div */
    transition={{ delay: 1, duration: 0.5, ease: "easeInOut" }}
    {...props}
  />
)

export const AnimationWrapper2: FC<{ children: ReactNode }> = (props) => (
  <FramerBox
    initial={{ y: "100%", opacity: 0 }}
    animate={{ y: "0%", opacity: 1 }}
    /* @ts-ignore: Incorrect type from chakra.div */
    transition={{ delay: 1, duration: 0.5, ease: "easeInOut" }}
    {...props}
  />
)
