export const getGlobalStyles = () => ({
  html: {
    backgroundColor: "Utils/screenBgColor",
    color: "Base/basePrimary",
    accentColor: "var(--chakra-colors-accentColor)",

    width: "100%",
    height: "100%",
    overscrollBehaviorY: "none",
    overscrollBehaviorX: "none",

    fontFamily: `Inter, -apple-system, BlinkMacSystemFont, "SF Pro Display", "SF Pro", "Segoe UI",
      "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif`,
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
  },
  body: {
    background: "none",
    width: "100%",
    height: "100%",
  },

  "#root, .App": {
    margin: "auto",
    maxWidth: "450px",
    minWidth: "300px",
    width: "100%",
    height: "100%",
    maxHeight: "100vh",
  },

  ".App": {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    zIndex: 1000,
  },

  code: {
    fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace',
  },

  a: {
    color: "Base/accentPrimary",
    _hover: {
      textDecoration: "none",
      color: "black",
    },
    _active: {
      textDecoration: "none",
      color: "black",
    },
  },
  ".chakra-slide": {
    zIndex: 1000,
  },
})
