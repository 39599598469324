import { useEffect } from "react"

import { useAmplitude } from "~/hooks/analytics/useAmplitude"
import { useIsContentLoaded } from "~/store/survey/selectors"

import { QuizPages } from "./pages/QuizPages/QuizPages"
import { useSentry } from "./shared/sentry"

// Test          [/]
// - Quiz1       [/Quiz[id]/]
// -- Question1  [/Quiz[id]/Question[id]]
// -- Question2  [/skin-characteristic/shave-comfort]
// - Quiz2       [/Quiz[id]/]
// -- Question1
// -- Question2

export const App = () => {
  const isLoading = false
  const isContentLoaded = useIsContentLoaded()

  const { logInit, logInterviewQuizStarted } = useAmplitude()
  const { setUserInfo } = useSentry()
  useEffect(() => {
    if (isContentLoaded) {
      logInit()
      setUserInfo()
      logInterviewQuizStarted()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContentLoaded, logInit, logInterviewQuizStarted])

  if (isLoading || !isContentLoaded) {
    return null // Loader in html
  }

  return (
    <div className="App">
      <QuizPages />
    </div>
  )
}
