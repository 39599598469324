import { Box, Flex, Image } from "@chakra-ui/react"
import { TTString } from "~/components/shared/AttributedString"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"

const TrustDoctorsCard = ({
  image,
  title,
  description,
}: {
  image: string
  title: string
  description: string
}) => {
  return (
    <Flex p={3} bgColor="white" borderRadius="20px" gap={3}>
      <Flex flexShrink={0}>
        <Image w="68px" h="68px" src={image} />
      </Flex>
      <Box>
        <Box textStyle="Subtitle/Secondary">{title}</Box>
        <Box opacity={0.7} mt={1} textStyle="Paragraph/Secondary">
          {description}
        </Box>
      </Box>
    </Flex>
  )
}

export const TrustDoctors = ({ title }: { title: TTString }) => {
  return (
    <Box>
      <BasePageHeader title={title} />
      <Flex flexDirection="column" mt={8} gap={2}>
        <TrustDoctorsCard
          image="https://storage.pora.ai/d78b127d206c85928eaab260ef5f7be9.webp"
          title="Nadia Kapleva, MD"
          description="A certified cosmetologist and chemical technologist with over 10 years of experience. Experienced Face Yoga instructor. Developer of innovative medical and cosmetic products."
        />
        <TrustDoctorsCard
          image="https://storage.pora.ai/ed12be8f34abcd864324feb9776e9ab3.webp"
          title="Asya Zubkov, MD"
          description="Cosmetic chemist and product development technologist at Lóvi. Member of the international community of cosmetic chemists (SCS, IFSCC)."
        />
        <TrustDoctorsCard
          image="https://storage.pora.ai/a83446bbd4328f51a06ccad57dec952b.webp"
          title="Elina Wilson, MD"
          description="Highly experienced dermatovenereologist and cosmetologist 10+ years of experience in the medical field.  She brings her extensive knowledge and expertise to the Lóvi team."
        />
      </Flex>
    </Box>
  )
}
