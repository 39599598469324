import { FC, useCallback, useEffect, useState } from "react"
import { Box, Button, Center, Flex, Heading, Spinner } from "@chakra-ui/react"
import { TT, TTString } from "~/components/shared/AttributedString"
import { defaultWelcomeImage } from "~/components/shared/WelcomeBlur"
import { LoviLogoIcon } from "~/components/shared/WelcomeBlur/Icons"
import { useProgress } from "~/hooks/useProgress"
import { FramerBox } from "~/components/shared/FramerBox"

type WelcomeSliderPageProps = {
  next: () => void
  slides: { image: string; title: TTString }[]
  skippable: boolean
  slide_duration_sec?: number
  loading_title?: string
}

const DEFAULT_IMAGE_DURATION_SEC = 2.5
const MIN_TOTAL_DURATION_SEC = 5
const LOADING_DELAY_SEC = 0.3

export const WelcomeSliderPage: FC<WelcomeSliderPageProps> = ({
  next,
  slides,
  skippable,
  loading_title,
  slide_duration_sec,
}) => {
  const [activeSlide, setActiveSlide] = useState(0)
  const slideDurationSec = slide_duration_sec || DEFAULT_IMAGE_DURATION_SEC
  const LOADING_DURATION_SEC = Math.max(slideDurationSec * slides.length, MIN_TOTAL_DURATION_SEC)

  const onLoadingFinish = useCallback(() => {
    next()
  }, [next])

  useEffect(() => {
    const stepTime = (LOADING_DURATION_SEC * 1000) / slides.length
    const intervalId = setInterval(() => {
      setActiveSlide((prevActiveSlideIndex) => {
        const slidesFinished = prevActiveSlideIndex === slides.length - 1
        if (slidesFinished) {
          clearInterval(intervalId)
          return prevActiveSlideIndex
        }

        return prevActiveSlideIndex + 1
      })
    }, stepTime)

    return () => clearInterval(intervalId)
  }, [slides.length, LOADING_DURATION_SEC])

  return (
    <Flex
      h="full"
      pos="relative"
      pb={{
        base: 6,
        lg: "100px",
      }}
      pt={{
        base: 4,
        lg: "60px",
      }}
      px={6}
    >
      {slides.map((slide, i) => (
        <Slide active={activeSlide === i} image={slide.image} title={slide.title} key={i} />
      ))}
      <Flex flex={1} flexDirection="column" justifyContent="space-between" zIndex={1}>
        <Flex justifyContent="center" textAlign="center">
          <LoviLogoIcon opacity={0.5} />
        </Flex>
        <Flex justifyContent="center" flexDirection="column" textAlign="center">
          {skippable && (
            <Button variant="inverted" onClick={onLoadingFinish}>
              Skip
            </Button>
          )}
          <ProgressBar
            showProgressBar={!skippable}
            duration={LOADING_DURATION_SEC}
            delay={LOADING_DELAY_SEC}
            onComplete={onLoadingFinish}
            title={loading_title || "Loading the quiz"}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}

function ProgressBar({
  onComplete,
  duration,
  delay,
  showProgressBar = true,
  title,
}: {
  onComplete: () => void
  duration: number
  delay: number
  showProgressBar: boolean
  title: string
}) {
  const { progress100 } = useProgress({
    delay,
    duration,
    onComplete,
  })

  const visualPercentage = Math.floor(progress100)
  const changeTitleColor = progress100 > 48

  if (!showProgressBar) {
    return null
  }

  return (
    <>
      <Flex
        mb={2.5}
        alignItems="center"
        justifyContent="center"
        textStyle="Subtitle/Tertiary"
        textColor="Base/neutralPrimary"
      >
        {title} <Spinner size="sm" ml={1.5} color="Base/neutralPrimary" />
      </Flex>
      <Flex
        backgroundColor="rgba(255, 255, 255, 0.20)"
        backdropFilter="blur(10px)"
        h="40px"
        borderRadius="100px"
        pos="relative"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          top={1}
          left={1}
          right={1}
          bottom={1}
          width={`calc(${progress100}% - 8px)`}
          pos="absolute"
          backgroundColor="Base/neutralPrimary"
          borderRadius="100px"
        />
        <Flex
          zIndex={1}
          textStyle="Subtitle/Primary"
          transition="color 200ms"
          textColor={changeTitleColor ? "Base/basePrimary" : "Base/neutralPrimary"}
        >
          {visualPercentage} %
        </Flex>
      </Flex>
    </>
  )
}

function Slide({ title, image, active }: { title: TTString; image: string; active: boolean }) {
  return (
    <>
      <Center
        visibility={active ? "visible" : "hidden"}
        pos="absolute"
        top={0}
        right={0}
        left={0}
        bottom={0}
        px={3}
        zIndex={1}
      >
        {active && (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore no problem in operation, although type error appears.
          <FramerBox
            {...{
              animate: {
                opacity: [0, 1],
                translateY: [48, 0],
              },
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore no problem in operation, although type error appears.
              transition: {
                duration: 0.3,
                ease: "easeInOut",
              },
            }}
          >
            <Heading
              textAlign="center"
              as="h1"
              size="Lead/Secondary"
              textColor="Base/neutralPrimary"
            >
              <TT>{title}</TT>
            </Heading>
          </FramerBox>
        )}
      </Center>
      <Box
        opacity={active ? 1 : 0}
        transition="opacity 300ms ease-in-out"
        bgPos="center"
        bgSize="cover"
        pos="absolute"
        left={0}
        right={0}
        top={0}
        bottom={0}
        bgColor="Base/neutralPrimary"
        backgroundImage={image || defaultWelcomeImage}
      />
    </>
  )
}
