import { createIcon } from "@chakra-ui/react"

export const ArrowIcon = createIcon({
  viewBox: "0 0 43 43",
  defaultProps: {
    fontSize: 43,
    fill: "none",
  },
  path: (
    <>
      <g clipPath="url(#clip0_763_27024)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.4807 17.5249C17.8296 16.8414 17.4503 15.9512 17.4759 14.8309C17.5552 11.3654 19.7234 9.39115 22.6594 8.41632C26.9466 6.99305 32.8904 7.73428 36.0761 8.9668C36.227 9.025 36.3016 9.1947 36.2432 9.34493C36.185 9.49588 36.0155 9.57109 35.8645 9.51289C32.7726 8.31642 27.0043 7.59088 22.8435 8.9719C20.1476 9.86707 18.1339 11.6615 18.0615 14.8445C18.0393 15.7918 18.3619 16.542 18.9109 17.1213C19.2951 16.7683 19.6841 16.4309 20.0776 16.1098C21.1639 15.2238 24.3705 13.4752 26.9749 13.1092C28.8432 12.8472 30.4001 13.2949 30.8256 15.0713C31.0619 16.0587 30.6412 16.9315 29.7856 17.6257C28.61 18.5807 26.5817 19.1961 24.5085 19.3012C22.7823 19.389 21.0285 19.1249 19.7143 18.4355C19.4257 18.2834 19.1573 18.1121 18.9145 17.9195C13.68 22.9162 9.61417 30.6823 8.90956 37.7274C8.89291 37.8879 8.75012 38.0057 8.58895 37.9892C8.42796 37.9735 8.31069 37.8298 8.32662 37.6695C9.04328 30.5095 13.1648 22.6176 18.4807 17.5249ZM19.3494 17.5131C19.7111 17.1822 20.0774 16.8654 20.4477 16.5635C21.4889 15.7148 24.5608 14.0398 27.0562 13.6893C28.5875 13.4739 29.9077 13.7519 30.2565 15.2077C30.4392 15.9724 30.0787 16.6333 29.417 17.1716C28.6884 17.7629 27.6053 18.2045 26.3944 18.4655C24.5559 18.8618 22.4225 18.8393 20.7592 18.2511C20.2321 18.065 19.753 17.821 19.3494 17.5131Z"
          fill="white"
          stroke="white"
          strokeWidth="0.969697"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.8098 30.1927C13.6375 31.653 10.6796 34.7749 8.85568 37.7741C8.77182 37.912 8.59159 37.9558 8.45364 37.8719C8.3157 37.788 8.27129 37.608 8.35515 37.47C10.2357 34.3769 13.2947 31.1667 16.5649 29.6611C16.7112 29.5936 16.8852 29.6579 16.9529 29.8049C17.0204 29.9513 16.9561 30.1252 16.8098 30.1927Z"
          fill="white"
          stroke="white"
          strokeWidth="0.969697"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.28585 37.4493C9.02513 34.6375 8.21543 30.8451 6.77912 28.3072C6.69935 28.1665 6.74848 27.9874 6.88942 27.9083C7.03016 27.8285 7.20854 27.8778 7.28831 28.0186C8.79294 30.6773 9.62633 34.6522 8.85186 37.5982C8.81113 37.7544 8.65037 37.848 8.49416 37.8072C8.33776 37.7658 8.24441 37.6057 8.28585 37.4493Z"
          fill="white"
          stroke="white"
          strokeWidth="0.969697"
        />
      </g>
      <defs>
        <clipPath id="clip0_763_27024">
          <rect
            width="34.425"
            height="34.425"
            fill="white"
            transform="matrix(0.258819 0.965926 0.965926 -0.258819 0.733398 9.41406)"
          />
        </clipPath>
      </defs>
    </>
  ),
})

export const MarkIcon = createIcon({
  viewBox: "0 0 28 28",
  defaultProps: {
    fontSize: 28,
    fill: "none",
  },
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5479 0.0002824C18.7019 -0.0237197 22.8277 1.48159 25.4196 4.7845C28.0134 8.08971 28.4597 12.4991 27.6047 16.6383C26.7281 20.8824 24.6756 25.0092 20.8481 26.9174C16.9942 28.8388 12.51 27.9764 8.63717 26.0947C4.7455 24.2037 1.24471 21.1965 0.274534 16.9209C-0.698136 12.6343 1.01395 8.24757 3.83 4.90925C6.51468 1.72666 10.426 0.0240998 14.5479 0.0002824Z"
        fill="currentColor"
      />
      <path
        d="M9 13.5357C11.5714 17.9071 13.0714 19 13.5 19L18.9643 10"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </>
  ),
})
