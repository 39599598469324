import { FC } from "react"
import { Stack, Box } from "@chakra-ui/react"

import { TTString } from "~/components/shared/AttributedString"
import { RiveOrImage } from "~/components/shared/RiveImage"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"
import { PreloadedStatic } from "~/static_images_to_preload"

export const CosmetologistVsLovi: FC<{ title: TTString }> = ({ title }) => (
  <Stack justify="flex-start" align="center" spacing={5}>
    <Stack justify="flex-start" align="center" spacing={3}>
      <BasePageHeader mb={3} mt={0} title={title} textAlign="center" />

      <Box textStyle="Paragraph/Primary" textAlign="center">
        Get a glow-up with a combination of unique face yoga exercises and a personalised skin care
        routine while <b>reducing visits to a cosmetologist</b>
      </Box>
    </Stack>

    <RiveOrImage width="full" src={PreloadedStatic.CosmetologistVsLoviImg} ratio={1.3} />

    <Box textStyle="Paragraph/Primary" textAlign="center">
      An additional benefit is that you will <b>feel much more confident</b> with your reflection in
      the mirror after a couple of days
    </Box>
  </Stack>
)
