import { FC } from "react"
import { Stack, Box } from "@chakra-ui/react"

import { TTString } from "~/components/shared/AttributedString"
import { RiveOrImage } from "~/components/shared/RiveImage"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"

export const SelfCareVsPora: FC<{ title: TTString }> = ({ title }) => (
  <Stack justify="flex-start" align="center" spacing={5}>
    <Stack justify="flex-start" align="center" spacing={3}>
      <BasePageHeader mb={3} mt={0} title={title} textAlign="center" />

      <Box textStyle="Paragraph/Primary" textAlign="center">
        Achieve <b>healthier skin</b> faster, see <b>visible results</b> and <b>save money</b> with
        Lóvi&apos;s tailored program!
      </Box>
    </Stack>

    <RiveOrImage
      width="full"
      src="https://public.rive.app/hosted/157559/109152/cu-oxDFU9UOMUrO9NxIsEA.riv"
      ratio={1.3}
    />

    <Box color="Base/baseSecondary" textAlign="center">
      <b>Feel much more confident</b> with your reflection in the mirror after a couple of weeks
    </Box>
  </Stack>
)
