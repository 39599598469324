import { FC, ReactNode } from "react"
import { Box, Heading, ListItem, OrderedList } from "@chakra-ui/react"
import num1Src from "./assets/num-1.svg"
import num2Src from "./assets/num-2.svg"
import num3Src from "./assets/num-3.svg"
import { useTranslation } from "react-i18next"

const NumberedTextListItem: FC<{ icon: string; children: ReactNode }> = ({ icon, children }) => (
  <ListItem
    _before={{
      content: '""',
      backgroundImage: icon,
      backgroundSize: "contain",
      display: "inline-block",
      width: "20px",
      height: "20px",
      marginLeft: "-20px",
      position: "relative",
      top: "3px",
    }}
  >
    {children}
  </ListItem>
)

export const NumberedTextList: FC = () => {
  const { t } = useTranslation()
  return (
    <Box>
      <Heading size="Header/Secondary" mb={4}>
        {t("scan_result_page.your_program")}
      </Heading>

      <OrderedList listStyleType="none" spacing={2} textStyle="Paragraph/Primary">
        <NumberedTextListItem icon={num1Src}>
          {t("scan_result_page.based_on_your_skin_data")}
        </NumberedTextListItem>
        <NumberedTextListItem icon={num2Src}>{t("scan_result_page.tailored")}</NumberedTextListItem>
        <NumberedTextListItem icon={num3Src}>{t("scan_result_page.designed")}</NumberedTextListItem>
      </OrderedList>
    </Box>
  )
}
