import { FC, useCallback, useMemo, useState } from "react"
import { Box, Flex } from "@chakra-ui/react"

import { TTString } from "~/components/shared/AttributedString"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"
import { NextPageCb } from "~/hooks/useNextQuiz"
import {
  NextButton,
  NextButtonBottomSlideUpContainer as NextButtonContainer,
  NEXT_BUTTON_CONTAINER_HEIGHT,
} from "~/components/shared/NextButton"

import { FaceAreaSelectQuestionScreen_FaceAreaSelectVariant } from "~/generated/interview_service"
import { FaceareaSelect, Area, FaceareaSelectVariant } from "./FaceareaSelect"

const toFaceareaSelectVariant = (
  v: FaceAreaSelectQuestionScreen_FaceAreaSelectVariant
): FaceareaSelectVariant | undefined => {
  switch (v) {
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_SWELLNESS:
      return "FEMALE_SWELLNESS"
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES:
      return "FEMALE_WRINKLES"
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_MALE_SWELLNESS:
      return "MALE_SWELLNESS"
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_MALE_WRINKLES:
      return "MALE_WRINKLES"
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_WRINKLES2:
      return "FEMALE_WRINKLES2"
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_REDNESS:
      return "FEMALE_REDNESS"
    case FaceAreaSelectQuestionScreen_FaceAreaSelectVariant.FACEAREA_SELECT_VARIANT_FEMALE_PIGMENTATION:
      return "FEMALE_PIGMENTATION"
    default:
      return undefined
  }
}

export const FaceareaSelectPage: FC<{
  title: TTString
  description?: string | undefined
  variant: FaceAreaSelectQuestionScreen_FaceAreaSelectVariant
  next: NextPageCb
}> = ({ title, description, variant, next }) => {
  const [variantsSelected, setVariantsSelected] = useState<Set<string>>(new Set())
  const onChange = useCallback(
    (selected: Set<Area>) => {
      setVariantsSelected(selected)
    },
    [setVariantsSelected]
  )
  const selectVariant = useMemo(() => toFaceareaSelectVariant(variant), [variant])

  const hasOneAtLeastAnswer = useMemo(() => variantsSelected.size > 0, [variantsSelected])

  const onNext = useCallback(() => {
    next(Array.from(variantsSelected.values()))
  }, [variantsSelected, next])

  return (
    <>
      <Flex direction="column" minHeight="100%" paddingTop="80px">
        <Box
          marginRight={6}
          paddingBottom={hasOneAtLeastAnswer ? NEXT_BUTTON_CONTAINER_HEIGHT : "auto"}
        >
          <BasePageHeader marginBottom={5} marginX={6} title={title} subTitle={description} />
          {selectVariant && <FaceareaSelect variant={selectVariant} onChange={onChange} />}
        </Box>
      </Flex>
      <NextButtonContainer visible={hasOneAtLeastAnswer}>
        <NextButton onClick={onNext} />
      </NextButtonContainer>
    </>
  )
}
