import type { QuestionId, QuizId } from "../types"
import { getInterviewId } from "~/api"
import {
  ActionData,
  Answer,
  InterviewServiceSaveAnswersRequest,
} from "~/generated/interview_service"

export const getSaveBody = ({
  userId,
  quizId,
  answers = {},
  answersV2 = {},
  nextQuizId,
}: {
  userId: UserId
  quizId: QuizId
  answers?: Record<QuestionId, string[]>
  answersV2?: Record<QuestionId, ActionData[]>
  nextQuizId?: QuizId
}) => {
  const quizAnswers: Answer[] = Object.keys(answers)
    .map((_question) => {
      const question = _question as QuestionId
      if (!answers[question]) {
        return null
      }

      return {
        question_id: question,
        values: answers[question]?.filter((answer) => Boolean(answer)),
        values2: answersV2[question]!,
      }
    })
    .filter(Boolean) as Answer[]

  return InterviewServiceSaveAnswersRequest.fromJSON({
    request: {
      userID: userId,
    },
    test_id: quizId,
    next_quiz_id: nextQuizId,
    answers: quizAnswers,
    interview_id: getInterviewId(),
  })
}
