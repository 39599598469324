import { FC } from "react"
import { Box, Flex, Heading } from "@chakra-ui/react"

import { NextButton } from "~/components/shared/NextButton"
import { IfTTExists, TT, TTString } from "~/components/shared/AttributedString"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import { RiveAlignment, RiveOrImage } from "~/components/shared/RiveImage"
import { VFlex } from "~/components/shared/VFlex"

type Variant = "firstImage" | "firstText"

const ImagePart: FC<{ image: string | undefined }> = ({ image }) =>
  image ? (
    <RiveOrImage width="full" src={image} ratio={1.3} alignment={RiveAlignment.Center} />
  ) : null

const TextPart: FC<{ title: TTString; description: TTString }> = ({ title, description }) => (
  <VFlex gap={3}>
    <IfTTExists if={title}>
      <Heading textAlign="left" as="h1" size="Header/Primary" whiteSpace="pre-line">
        <TT>{title}</TT>
      </Heading>
    </IfTTExists>
    <IfTTExists if={description}>
      <Box textStyle="Paragraph/Primary" whiteSpace="pre-line">
        <TT>{description}</TT>
      </Box>
    </IfTTExists>
  </VFlex>
)

export const MessageWithImagePage: FC<{
  title: TTString
  description: TTString
  image: string | undefined
  variant: Variant
  next: () => void
}> = ({ variant = "firstImage", title, description, image, next }) => {
  return (
    <BasePageWrapper h="full" paddingBottom={4} gap={6}>
      {variant === "firstText" ? (
        <>
          <TextPart title={title} description={description} />
          <ImagePart image={image} />
        </>
      ) : (
        <>
          <ImagePart image={image} />
          <TextPart title={title} description={description} />
        </>
      )}
      <Flex flexGrow={1} justifyContent="flex-end" alignItems="flex-end">
        <NextButton onClick={next} />
      </Flex>
    </BasePageWrapper>
  )
}
