import { useMemo } from "react"

const track = (action: string, values?: Record<string, unknown>) => {
  if (window.gtag) {
    window.gtag("event", action, values)
  }
}

export const captureException = (message: string) => {
  track("exception", { description: message, fatal: true })
}

export const captureError = (message: string) => {
  track("exception", { description: message, fatal: false })
}

export const useGoogleAnalytics = () => {
  const purchase = (values?: Record<string, unknown>) => track("purchase", values)
  const pageView = (path: string, values: object = {}) =>
    track("page_view", {
      page_path: path,
      page_title: window.document.title,
      ...values,
    })
  return useMemo(
    () => ({
      track,
      purchase,
      pageView,
    }),
    []
  )
}
