import { FC, ReactNode } from "react"
import { Stack, Box, List, ListItem, ListIcon } from "@chakra-ui/react"
import { MarkIcon } from "~/components/shared/MarkIcon"
import { TTString } from "~/components/shared/AttributedString"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"

const ListItemImpl: FC<{ children: ReactNode }> = ({ children }) => (
  <ListItem display="inline-flex" alignItems="center">
    <ListIcon as={MarkIcon} boxSize={8} p={0.5} mr={1} color="Base/accentSecondary" />
    {children}
  </ListItem>
)

export const AboutProgram2: FC<{ title: TTString }> = ({ title }) => (
  <>
    <BasePageHeader mt="56px" mb={8} title={title} />

    <Stack justify="flex-start" align="flex-start" spacing={3}>
      <Box textStyle="Subtitle/Primary">The subscription includes</Box>
      <List spacing={1} textStyle="Paragraph/Primary" mt={0}>
        <ListItemImpl>Real-time skin analysis technology</ListItemImpl>
        <ListItemImpl>Guidance approved by medical team</ListItemImpl>
        <ListItemImpl>Precise product matches for your skin</ListItemImpl>
        <ListItemImpl>Custom facelifting techniques</ListItemImpl>
        <ListItemImpl>Smart skincare product scanner</ListItemImpl>
        <ListItemImpl>Visibly improve your skin quality</ListItemImpl>
      </List>
    </Stack>
  </>
)
