import { useCallback, FC, useId, useMemo } from "react"
import { AspectRatio, Box, Center, Flex, Icon, Image, Link } from "@chakra-ui/react"
import { SingleSelect } from "../../shared/SingleSelect/SingleSelect"
import type { NextPageCb } from "../../../hooks/useNextQuiz"
import { SelectLayoutEnum } from "../../../api/QuestionType"
import { TTString } from "../../shared/AttributedString"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"
import { SingleVariantQuestionScreen } from "~/generated/interview_service"
import { Logo } from "~/components/shared/Icons"
import { VFlex } from "~/components/shared/VFlex"

const HEADER_IMAGE_RATIO = 21 / 9

export const QuestionImage: FC<{ src: string }> = ({ src }) => (
  <AspectRatio ratio={HEADER_IMAGE_RATIO} marginBottom={2}>
    <Image src={src} borderBottomRadius="xl" align="center top" objectFit="cover" />
  </AspectRatio>
)

export const TermsLinks: FC = () => (
  <VFlex textStyle="Paragraph/Tertiary" color="Base/baseSecondary" alignItems="center" marginY={4}>
    <Box>By continuing you agree to our</Box>
    <Flex
      sx={{
        a: {
          color: "Base/baseSecondary",
        },
      }}
    >
      <Link textStyle="Subtitle/Hint" target="_blank" href="https://lovi.care/terms/">
        Terms of Use
      </Link>
      <Box mx={1}>|</Box>
      <Link textStyle="Subtitle/Hint" target="_blank" href="https://lovi.care/privacy-policy/">
        Privacy Policy
      </Link>
    </Flex>
  </VFlex>
)

type Variant = {
  title: string
  description?: string | undefined
  image?: string | undefined
  icon?: string | undefined
  deselect_other_variants?: boolean
}
export const QuestionPageSingleSelect: FC<{
  title: TTString
  image?: string | undefined
  description?: string | undefined
  upTitle?: TTString
  layout: SelectLayoutEnum
  variantsTitles: SingleVariantQuestionScreen["variants"]
  variantProperties: SingleVariantQuestionScreen["variant_properties"]
  viewVariant?: "singleVariant.ageOnWelcome" | string | undefined
  next: NextPageCb
}> = ({
  title,
  upTitle,
  description,
  variantsTitles,
  variantProperties,
  image,
  layout = SelectLayoutEnum.ONE_COLUMN,
  viewVariant = "",
  next,
}) => {
  const variants = usePrepareVariants({ variantsTitles, variantProperties, layout })
  const id = useId()
  const onSelect = useCallback(
    (variant: string) => {
      next([variant])
    },
    [next]
  )
  const isWelcomeStyle = viewVariant === "singleVariant.ageOnWelcome"
  const basePageHeaderProps = isWelcomeStyle
    ? { subTitleProps: { color: "Base/baseSecondary" }, textAlign: "center" as const }
    : undefined

  return (
    <Flex direction="column" minHeight="100%" paddingTop={image || isWelcomeStyle ? 0 : 12}>
      {image && <QuestionImage src={image} />}
      {isWelcomeStyle && (
        <Center mt={4} mb={2}>
          <Icon as={Logo} w="47px" h="24px" color="Base/primary" />
        </Center>
      )}

      <Box marginX={6}>
        <BasePageHeader
          marginTop={4}
          marginBottom={6}
          title={title}
          upTitle={upTitle}
          subTitle={description}
          {...basePageHeaderProps}
        />
        <SingleSelect key={id} variants={variants} onChange={onSelect} layout={layout} />
      </Box>
      {isWelcomeStyle && <TermsLinks />}
    </Flex>
  )
}

function usePrepareVariants({
  variantsTitles,
  variantProperties,
  layout,
}: {
  variantsTitles: SingleVariantQuestionScreen["variants"]
  variantProperties: SingleVariantQuestionScreen["variant_properties"]
  layout: SingleVariantQuestionScreen["layout"]
}): Variant[] {
  return useMemo(
    () =>
      variantsTitles.map((title) => {
        const rest = variantProperties?.[title]
        if (rest) {
          return {
            title,
            image:
              layout === SelectLayoutEnum.ONE_COLUMN_IMAGES ||
              layout === SelectLayoutEnum.TWO_COLUMNS_IMAGES ||
              layout === SelectLayoutEnum.TWO_COLUMNS_IMAGES_TRANSPARENT
                ? rest.image_link
                : undefined,
            icon:
              layout === SelectLayoutEnum.ONE_COLUMN || layout === SelectLayoutEnum.TWO_COLUMNS
                ? rest.image_link
                : undefined,
            description: rest.description,
            deselect_other_variants: rest.deselect_other_variants ?? false,
          }
        }
        return { title }
      }),
    [layout, variantProperties, variantsTitles]
  )
}
