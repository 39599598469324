import { FC } from "react"
import { Box, BoxProps, Center, Flex } from "@chakra-ui/react"

import { MessagesAnimation } from "../../shared/MessagesAnimation/MessagesAnimation"
import circle1Svg from "./circle1.svg"
import circle2Svg from "./circle2.svg"
import { rotationAnimation } from "~/components/shared/rotationAnimation"

const Circle: FC<BoxProps> = (props) => (
  <Box
    display="inline-block"
    position="absolute"
    width="320px"
    height="320px"
    backgroundSize="contain"
    backgroundRepeat="no-repeat"
    backgroundPosition="center"
    {...props}
  />
)

const CircleAnimation: FC = () => (
  <Box position="relative" left="0" width="320px">
    <Circle bgImage={circle1Svg} animation={rotationAnimation({ duration: 5 })} />
    <Circle bgImage={circle2Svg} animation={rotationAnimation({ duration: 8, reverse: true })} />
  </Box>
)

export const TitlesLoaderPage: FC<{
  messages: string[]
  next: () => void
  delay?: number
}> = ({ messages, delay = 2, next }) => (
  <Flex w="full" h="100vh" direction="column" justifyContent="center" alignItems="center">
    <CircleAnimation />
    <Center width="full" height="320px">
      <MessagesAnimation messages={messages} delay={delay} onComplete={next} />
    </Center>
  </Flex>
)
