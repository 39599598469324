import { FC, ReactNode } from "react"
import { Stack, Box, List, ListItem, ListIcon } from "@chakra-ui/react"
import { MarkIcon } from "~/components/shared/MarkIcon"
import { TTString } from "~/components/shared/AttributedString"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"

const ListItemImpl: FC<{ children: ReactNode }> = ({ children }) => (
  <ListItem display="inline-flex" alignItems="center" mt={0}>
    <ListIcon as={MarkIcon} boxSize={8} p={0.5} mr={1} color="Base/accentPrimary" />
    {children}
  </ListItem>
)

export const WhatAreBenefitsContent: FC<{ title: TTString }> = ({ title }) => (
  <>
    <BasePageHeader mb={4} title={title} />

    <Stack justify="flex-start" align="flex-start" spacing={8}>
      <Box textStyle="Paragraph/Primary">
        It’s a skincare program that combines cosmetics recommendation, face massage techniques,
        designed to address your skin goals and concerns, powered by AI and supervised by
        professional dermatologists
      </Box>
      <Box>
        <Box textStyle="Header/Tertiary" mb={2}>
          What are the benefits?
        </Box>
        <List spacing={1} textStyle="Paragraph/Primary">
          <ListItemImpl>Visibly improve your skin quality</ListItemImpl>
          <ListItemImpl>Increase the collagen synthesis</ListItemImpl>
          <ListItemImpl>Raise the blood & lymph circulation</ListItemImpl>
          <ListItemImpl>Save the budget on skincare products</ListItemImpl>
        </List>
      </Box>
      <Box>
        <Box textStyle="Header/Tertiary" mb={2}>
          How does it work?
        </Box>
        <Box textStyle="Paragraph/Primary">
          Regular and complex daily routine helps to keep skin healthy. Pora’s medical board,
          together with AI, created a skincare workflow for different skin types and issues aiming
          for a singular objective: to promote overall skin health and vitality.
        </Box>
      </Box>
    </Stack>
  </>
)
