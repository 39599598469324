import { MotionValue } from "framer-motion"
import { chakra, ChakraProps } from "@chakra-ui/react"
import { useEffect, useState } from "react"

export function MotionValuePrinter({
  motionValue,
  ...rest
}: {
  motionValue: MotionValue & ChakraProps
}) {
  const [value, setValue] = useState(motionValue.get())
  useEffect(() => {
    motionValue.on("change", setValue)
  }, [motionValue])
  return <chakra.span {...rest}>{value}</chakra.span>
}
