import { FC, ReactNode } from "react"
import {
  Box,
  Flex,
  Heading,
  List,
  ListItem,
  ListIcon,
  Text,
  FlexProps,
  SlideFade,
} from "@chakra-ui/react"

import type { NextPageCb } from "~/hooks/useNextQuiz"
import { AttributedString, AttributedStringType } from "~/components/shared/AttributedString"
import { MarkIcon } from "~/components/shared/MarkIcon"
import { PresentationImage, PresentationImageType } from "~/components/shared/PresentationImage"
import {
  NextButton,
  NextButtonBottomSlideUpContainer as NextButtonContainer,
} from "~/components/shared/NextButton"
import {
  MessageListScreen_ListItem_BulletSymbol as Symbol,
  type MessageListScreen_ListItem,
  type MessageListScreen_Explanation,
} from "~/generated/interview_service"

import ProgressIconWebp from "./assets/ProgressIcon.webp"
import ProgressIconMp4 from "./assets/ProgressIcon.mp4"
import { useIsLoviTheme } from "~/theme/themeProvider"

const ProgressIcon: FC = () => (
  <video
    muted
    loop
    playsInline
    autoPlay
    src={ProgressIconMp4}
    poster={ProgressIconWebp}
    width="28"
    height="28"
  >
    <img src={ProgressIconWebp} alt="" title="" width="28" height="28" />
  </video>
)

type Icon = undefined | "progress" | "mark"

const BulletsListItem: FC<{
  icon?: Icon
  children?: ReactNode
  title?: AttributedStringType
  delay?: number
}> = ({ icon = "none", children, title, delay = 0 }) => {
  const LIcon = icon === "progress" ? ProgressIcon : icon === "mark" ? MarkIcon : Box
  const isLoviTheme = useIsLoviTheme()
  return (
    <SlideFade
      in
      offsetY="5rem"
      transition={{ enter: { ease: "easeInOut", duration: 0.3, delay } }}
    >
      <ListItem>
        <Flex direction="column" gap={3}>
          <Flex
            direction="row"
            alignItems="center"
            gap={3}
            textStyle={isLoviTheme ? "Subtitle/Primary" : "MediumSubtitlePrimary"}
          >
            <ListIcon
              as={LIcon}
              boxSize="7"
              m={0}
              color={isLoviTheme ? "Other/Green" : "Other/Success"}
            />
            {title && <AttributedString>{title}</AttributedString>}
          </Flex>

          {children && <Box marginLeft={10}>{children}</Box>}
        </Flex>
      </ListItem>
    </SlideFade>
  )
}

const Card: FC<FlexProps> = (props) => {
  const isLoviTheme = useIsLoviTheme()
  return (
    <Flex
      direction="column"
      bgColor={isLoviTheme ? "white" : "Base/neutralDisabled"}
      borderRadius="brand-lg"
      padding={4}
      gap={2}
      {...props}
    />
  )
}

const CardHeader: FC<{ children: AttributedStringType }> = ({ children }) => (
  <Heading size={useIsLoviTheme() ? "Header/Secondary" : "MediumSecondary"}>
    <AttributedString disableEm={useIsLoviTheme()}>{children}</AttributedString>
  </Heading>
)

const CardText: FC<{ children: AttributedStringType }> = ({ children }) => (
  <Text textStyle="RegularParagraphSecondary">
    <AttributedString>{children}</AttributedString>
  </Text>
)

const CardNotice: FC<{ children: ReactNode; icon?: PresentationImageType }> = ({
  children,
  icon,
}) => (
  <Flex
    textStyle="RegularParagraphSecondary"
    color="Base/baseSecondary"
    gap={2}
    alignItems="center"
  >
    {icon && <PresentationImage source={icon} alt="" h="28px" display="inline-block" />}
    {children}
  </Flex>
)

// eslint-disable-next-line @typescript-eslint/ban-types
const symbol2icon = (s?: Symbol): Icon => {
  switch (s) {
    case Symbol.BULLET_SYMBOL_GREEN_MARK:
      return "mark"
    case Symbol.BULLET_SYMBOL_PROCESSING:
      return "progress"
  }
  return undefined
}

const Explanation: FC<{ data: MessageListScreen_Explanation }> = ({ data }) => {
  const { explanation } = data
  switch (explanation?.$case) {
    case "title":
      return <CardHeader>{explanation.title}</CardHeader>
    case "text":
      return <CardText>{explanation.text}</CardText>
    case "image":
      return <PresentationImage source={explanation.image} w="full" />
    case "notice":
      return <CardNotice icon={explanation.notice.image}>{explanation.notice.text}</CardNotice>
    default:
      return null
  }
}

const Explanations: FC<{ content: MessageListScreen_Explanation[] }> = ({ content }) => {
  if (!content) {
    return null
  }

  const children = content.map((explanation, i) => <Explanation data={explanation} key={i} />)

  return <Card>{children}</Card>
}

const BulletsList: FC<{ items: MessageListScreen_ListItem[] }> = ({ items }) => {
  const DELAY_BETWEEN_ITEMS = 0.2

  return (
    <List spacing={5}>
      {items.map(({ symbol, title, explanations = [] }, i) => (
        <BulletsListItem
          key={i}
          title={title}
          icon={symbol2icon(symbol)}
          delay={DELAY_BETWEEN_ITEMS * i}
        >
          <Explanations content={explanations} />
        </BulletsListItem>
      ))}
    </List>
  )
}

export const PageHeader: FC<{ title?: AttributedStringType | string }> = ({ title }) =>
  title ? (
    <Heading size={{ base: "MediumHeaderSecondary", sm: "MediumHeaderPrimary" }}>
      <AttributedString>{title}</AttributedString>
    </Heading>
  ) : null

export const MessageListPage: FC<{
  title?: AttributedStringType
  items: MessageListScreen_ListItem[]
  next: NextPageCb
}> = ({ title, items, next: onNext }) => {
  return (
    <>
      <Flex
        direction="column"
        alignItems="flex-start"
        gap={6}
        paddingTop="64px"
        paddingBottom="88px"
        marginX={6}
      >
        <PageHeader title={title} />
        <BulletsList items={items} />
      </Flex>
      <NextButtonContainer visible>
        <NextButton onClick={onNext} />
      </NextButtonContainer>
    </>
  )
}
