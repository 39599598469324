import { FC, useMemo } from "react"
import type { TTString } from "~/components/shared/AttributedString"
import { NextPageCb } from "~/hooks/useNextQuiz"
import {
  NextButton,
  NextButtonBottomSlideUpContainer as NextButtonContainer,
} from "~/components/shared/NextButton"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"

import { CosmetologistVsLovi } from "./CosmetologistVsLovi"
import { CosmetologistVsPora } from "./CosmetologistVsPora"
import { EstheticianVsPora } from "./EstheticianVsPora"
import { ScienceResearch } from "./ScienceResearch"
import { SelfCareVsPora } from "./SelfCareVsPora"
import { TrustDoctors } from "./TrustDoctors"
import { WhatAreBenefitsContent } from "./WhatAreBenefitsContent"
import { WhatIsFaceYoga } from "./WhatIsFaceYoga"
import { AboutProgram2 } from "./AboutProgram2"

const variantToComponentMap = {
  __DEFAULT__: CosmetologistVsPora,
  static_cosmetologist_vs_pora_screen: CosmetologistVsPora,
  static_about_pora_program_screen: WhatAreBenefitsContent,
  static_self_care_vs_pora_screen: SelfCareVsPora,
  static_esthetician_vs_lovi: EstheticianVsPora,
  static_what_is_face_yoga: WhatIsFaceYoga,
  static_trust_doctors: TrustDoctors,
  static_cosmetologist_vs_lovi_screen: CosmetologistVsLovi,
  static_science_research: ScienceResearch,
  static_about_program_2: AboutProgram2,
} as const

type Variant = keyof typeof variantToComponentMap

export const switchComponent = (variant: string): FC<{ title: TTString }> => {
  if (variantToComponentMap[variant as Variant] != null) {
    return variantToComponentMap[variant as Variant]
  }
  return variantToComponentMap.__DEFAULT__
}

export const StaticPage: FC<{
  title: TTString
  variant: Variant
  next: NextPageCb
}> = ({ title, variant, next }) => {
  const RenderComponent = useMemo(() => switchComponent(variant), [variant])
  return (
    <BasePageWrapper>
      <RenderComponent title={title} />
      <NextButtonContainer visible>
        <NextButton onClick={next} />
      </NextButtonContainer>
    </BasePageWrapper>
  )
}
