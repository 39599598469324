import { FC, useEffect } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import { useNavigate } from "react-router-dom"

import { apiUpdateUserId } from "../../api"
import { clearUserId, getUserId } from "../../hooks/useInitialization/initUtils"
import { runCompletionAction } from "../../hooks/useNextQuiz"
import { useAmplitude } from "../../hooks/analytics/useAmplitude"
import { useMetaPixel } from "../../hooks/analytics/useMetaPixel"
import { useUserId } from "../../hooks/useQuizHistory"
import { useTiktokPixel } from "~/hooks/analytics/useTiktokPixel"

const noop = () => void 0
export const LOGIN_CALLBACK_URL = "/login-cb/"
const returnTo = String(new URL("/", window.location.href))

const updateUserId = async (newUserId: UserId, navigate: (url: string) => void) => {
  const oldUserId = getUserId() ?? newUserId
  const resp = await apiUpdateUserId(oldUserId, newUserId)
  //Maybe update internal userId? setUserId(newUserId);
  runCompletionAction({
    completionAction: resp.quiz?.on_completion_action,
    userId: newUserId,
    login: noop,
    navigate,
  })
}

export const LoginCallback: FC = () => {
  const { isLoading } = useAuth0()
  const { authUserId } = useUserId()
  const { logLogin } = useAmplitude()
  const { logCompleteRegistration } = useMetaPixel()
  const { completeRegistration } = useTiktokPixel()
  const navigate = useNavigate()
  useEffect(() => {
    if (!isLoading && authUserId) {
      updateUserId(authUserId, navigate)
      logCompleteRegistration()
      completeRegistration()
      logLogin(authUserId)
    }
  }, [completeRegistration, isLoading, authUserId, logLogin, logCompleteRegistration, navigate])
  return null
}

export const Login: FC = () => {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0()
  const navigate = useNavigate()
  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        console.log("Login / isAuthenticated")
        navigate("/")
      } else {
        loginWithRedirect({ returnTo })
      }
    }
  }, [isLoading, isAuthenticated, loginWithRedirect, navigate])

  return null
}

export const Logout: FC = () => {
  const { logout, isAuthenticated, isLoading } = useAuth0()
  const navigate = useNavigate()
  clearUserId()
  useEffect(() => {
    if (!isLoading) {
      if (isAuthenticated) {
        console.log("Logout / isAuthenticated")
        logout({ returnTo })
      } else {
        navigate("/")
        //loginWithRedirect({ redirectUri: String(new URL('/logout/', window.location.href)) })
      }
    }
  }, [isLoading, isAuthenticated, logout, navigate])

  return null
}
