import { keyframes } from "@emotion/react"

const ROTATE_KEYFRAMES = keyframes`
0% { rotate: 0; }
100% { rotate: 359deg; }
`

export const rotationAnimation = ({
  duration = 5,
  reverse = false,
}: {
  duration: number
  reverse?: boolean
}): string => `${ROTATE_KEYFRAMES} ${duration}s linear 0s infinite ${reverse ? "reverse" : ""}`
