import { FC } from "react"
import { Stack, Box } from "@chakra-ui/react"

import { TTString } from "~/components/shared/AttributedString"
import { RiveOrImage } from "~/components/shared/RiveImage"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"

export const EstheticianVsPora: FC<{ title: TTString }> = ({ title }) => (
  <Stack justify="flex-start" align="left" spacing={5}>
    <Stack justify="flex-start" align="left" spacing={3}>
      <BasePageHeader mb={3} mt={0} title={title} textAlign="left" />

      <Box textStyle="Paragraph/Primary" textAlign="left">
        <b>Get a glow-up & reduce visits to an estetician</b> with a combination of face exercises
        and a tailored skin care routine
      </Box>
    </Stack>

    <RiveOrImage
      width="full"
      src="https://public.rive.app/hosted/157559/96713/erAAwLLgpEazXyp-2oA76w.riv"
      ratio={1.3}
    />

    <Box color="Base/baseSecondary" textAlign="left">
      <b>You will feel much more confident</b> with your reflection in the mirror after a couple of
      weeks
    </Box>
  </Stack>
)
