import { FC, useCallback, useMemo } from "react"
import { Box, Button, Center, Flex, Heading, SimpleGrid } from "@chakra-ui/react"
import {
  BgGradient,
  BgImage,
  BlurBox,
  LoviLogoIcon,
  AllVariantButton,
} from "~/components/shared/WelcomeBlur"
import { TT, TTString } from "~/components/shared/AttributedString"

import type { VariantProperties } from "~/generated/interview_service"

type WelcomeSelectPageProps = {
  title: TTString
  description: TTString
  image: string
  next: (answers: string[]) => void
  variants: VariantProperties[]
  selectTitle: TTString
}

export const WelcomeSelect2Page: FC<WelcomeSelectPageProps> = ({
  next,
  image,
  title,
  description,
  variants: _variants,
  selectTitle,
}) => {
  const handleChange = useCallback(
    (selected: string) => {
      next([selected])
    },
    [next]
  )

  /* TODO use reduce here */
  const variants = useMemo(() => _variants.filter((v) => !v.select_all_variants), [_variants])
  const selectAllVariant = useMemo(
    () => _variants.filter((v) => v.select_all_variants)[0],
    [_variants]
  )

  return (
    <>
      <BgImage bgImage={image} />
      <BgGradient />

      <Flex
        h="full"
        pos="relative"
        pt={4}
        pb={6}
        px={6}
        flexDirection="column"
        justifyContent="space-between"
      >
        <Center>
          <LoviLogoIcon />
        </Center>

        <Flex justifyContent="center" flexDirection="column" textAlign="center">
          <BlurBox mb={{ base: 2, lg: 5 }}>{description}</BlurBox>
          <Heading mb={8} as="h1" size="Header/Secondary" textColor="Base/neutralPrimary">
            <TT>{title}</TT>
          </Heading>
          <Box
            textAlign="center"
            mb={4}
            textColor="Base/neutralPrimary"
            opacity={0.7}
            textStyle="Subtitle/Secondary"
          >
            <TT>{selectTitle}</TT>
          </Box>
          <SimpleGrid columns={2} spacing={[2, 2]} w="full">
            {variants.map((v, i) => {
              return (
                <Button
                  variant="inverted"
                  paddingX={6}
                  minW="full"
                  minH="72px"
                  onClick={() => handleChange(v.text)}
                  whiteSpace="pre-line"
                  key={i}
                >
                  {v.text}
                </Button>
              )
            })}
          </SimpleGrid>
          {selectAllVariant && (
            <AllVariantButton
              color="white"
              mt={4}
              onClick={() => handleChange(selectAllVariant.text)}
              title={selectAllVariant.text}
            />
          )}
        </Flex>
      </Flex>
    </>
  )
}
