import { ReactNode, FC, useMemo } from "react"
import { useLocation } from "react-router-dom"
import { motion, AnimatePresence } from "framer-motion"

type DirectionSign = 1 | -1

type CustomArgs = {
  direction: DirectionSign
  isAnimated: boolean
}
const variants = {
  initial: ({ direction = 1, isAnimated = true }: CustomArgs) => ({
    x: direction > 0 ? "100%" : "-100%",
    y: 0,
    scale: "100%",
    opacity: 1,
    transition: { duration: isAnimated ? 0.15 : 0, ease: "easeIn" },
  }),
  animate: ({ isAnimated = true }: CustomArgs) => ({
    x: 0,
    y: 0,
    scale: "100%",
    opacity: 1,
    transition: { duration: isAnimated ? 0.2 : 0, ease: "easeInOut" },
  }),
  exit: ({ isAnimated = true }: CustomArgs) => ({
    opacity: 0.2,
    transition: { duration: isAnimated ? 0.02 : 0, ease: "easeOut" },
  }),
}
const defaultStyle = { flex: 1 }
const SlidingMotionEffect: FC<{
  direction: DirectionSign
  isAnimated: boolean
  children: ReactNode
}> = ({ direction, isAnimated, children }) => (
  <motion.div
    style={defaultStyle}
    variants={variants}
    custom={{ direction, isAnimated }}
    initial="initial"
    exit="exit"
    animate="animate"
  >
    {children}
  </motion.div>
)

const locationHistory: Array<string> = [] // Stack
const isBackward = (loc: string): boolean => {
  const idx = locationHistory.findIndex((v) => v === loc)
  if (idx > -1) {
    locationHistory.splice(0, idx)
    return true
  }
  locationHistory.unshift(loc)
  return false
}

export const SlidingSwitch: FC<{ isAnimated?: boolean; children: ReactNode }> = ({
  isAnimated = true,
  children,
}) => {
  const location = useLocation()
  const isBack: DirectionSign = useMemo(
    () => (isBackward(location.pathname) ? -1 : 1),
    [location.pathname]
  )

  return (
    <AnimatePresence mode="wait">
      <SlidingMotionEffect direction={isBack} isAnimated={isAnimated} key={location.pathname}>
        {children}
      </SlidingMotionEffect>
    </AnimatePresence>
  )
}
