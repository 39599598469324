import { FC } from "react"
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  Icon,
  Image,
  Link,
} from "@chakra-ui/react"

import { noop } from "~/utils"
import { VFlex } from "~/components/shared/VFlex"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import { NextButton } from "~/components/shared/NextButton"
import { EmailInputFormControl, useEmailInputHandlers } from "~/components/shared/EmailInput"
import { NextPageCb } from "~/hooks/useNextQuiz"
import { SkinIssues } from "~/generated/paywall"

import { FaceScannerResultContent } from "./FaceScannerResultPage"
import avatarsSrc from "./assets/avatars.webp"
import ShieldSvg from "./assets/shield.svg?react"
import { Trans, useTranslation } from "react-i18next"

const EmailFormContent: FC<{ next: NextPageCb }> = ({ next }) => {
  const { isLoading, isInvalid, isEmailValid, onSubmit, onInputType } = useEmailInputHandlers(next)
  const { t } = useTranslation()

  return (
    <VFlex as="form" gap={6} placeItems="center" textAlign="center">
      <VFlex gap={3} w="full">
        <Heading as="h3" size="Header/MonospacedSecondary">
          {t("email_form_content.title")}
        </Heading>
        <Box textStyle="Paragraph/Secondary">{t("email_form_content.subtitle")}</Box>
      </VFlex>
      <VFlex gap={3} w="full" placeItems="center">
        <EmailInputFormControl
          isInvalid={isInvalid}
          isLoading={isLoading}
          onInputType={onInputType}
          placeholder={t("email_form_content.input_placeholder")}
          errorMessage={t("email_form_content.error")}
        />

        <Flex textStyle="Paragraph/Secondary">
          <Icon as={ShieldSvg} w="20px" h="20px" color="Other/Green" marginRight="2px" />
          <Box textAlign="left" color="Base/baseSecondary" sx={{ textWrap: "pretty" }}>
            {t("email_form_content.privacy")}
          </Box>
        </Flex>
      </VFlex>
      <VFlex gap={3} w="full" placeItems="center">
        <NextButton
          type="submit"
          onClick={onSubmit}
          isDisabled={!isEmailValid}
          isLoading={isLoading}
          loadingText={t("email_form_content.loading")}
          label={t("email_form_content.submit_btn")}
        />

        <Flex textStyle="Paragraph/Secondary">
          <Image
            src={avatarsSrc}
            w="48px"
            h="20px"
            alt=""
            display="inline-block"
            marginRight="1ex"
          />
          {t("email_form_content.already_joined")}
        </Flex>
      </VFlex>
      <Box
        textStyle="Paragraph/Tertiary"
        color="Base/baseSecondary"
        w="full"
        sx={{ "& :link": { color: "black" } }}
      >
        <Trans
          i18nKey="email_form_content.footer"
          components={{
            terms: (
              <Link target="_blank" href="https://lovi.care/terms/">
                Terms
              </Link>
            ),
            privacy: (
              <Link target="_blank" href="https://lovi.care/privacy-policy/">
                Privacy
              </Link>
            ),
            care: <Link href="mailto:care@lovi.care">care@lovi.care</Link>,
          }}
        />
      </Box>
    </VFlex>
  )
}

export const FaceScannerResultWithEmailInputPage: FC<{
  issues: SkinIssues[]
  next: NextPageCb
}> = ({ issues, next }) => (
  <BasePageWrapper minH="full" overflowY="auto" paddingX={0} bgColor="white">
    <FaceScannerResultContent issues={issues} />

    <Drawer isOpen placement="bottom" onClose={noop}>
      <DrawerOverlay bg="extra.BlackAlpha50" backdropFilter="blur(6px)" />
      <DrawerContent bg="none" placeItems="center">
        <Flex
          bg="Base/neutralPrimary"
          padding={6}
          paddingBottom={10}
          borderTopRadius="brand24"
          maxWidth="450px"
          w="full"
        >
          <DrawerBody padding={0} margin={0}>
            <EmailFormContent next={next} />
          </DrawerBody>
        </Flex>
      </DrawerContent>
    </Drawer>
  </BasePageWrapper>
)
