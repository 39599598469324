import { useMemo } from "react"
import { /* useRouteMatch, */ generatePath, matchPath, useMatch } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import { getTestQueryParams } from "~/utils"
import { getConfig } from "../config"
import type { QuestionId, QuizId } from "../types"
import { getUserId } from "./useInitialization/initUtils"

// TODO move to separate file
export const useUserId = () => {
  const { user } = useAuth0()
  const authUserId = user?.sub as UserId | undefined
  const internalUserId = getUserId()
  const userId = authUserId ? authUserId : internalUserId

  return { authUserId, internalUserId, userId }
}

export const buildQuizUrl = (quizId: QuizId): string => {
  const queryParams = new URLSearchParams({
    ...getTestQueryParams(),
  })

  return generatePath(`/${getConfig().constants.quizUrlPrefix}/:quizId/?${queryParams}`, {
    quizId,
  })
}

/* FIXME: use navigate with params */
export const buildQuestionUrl = (quizId: QuizId, questionId: QuestionId): string => {
  const queryParams = new URLSearchParams({
    ...getTestQueryParams(),
  })

  return generatePath(
    `/${getConfig().constants.quizUrlPrefix}/:quizId/:questionId/?${queryParams}`,
    {
      quizId,
      questionId,
    }
  )
}

/* TODO simplify return object to { quiz, question } */
export const useQuizHistory = () => {
  const { params: { quizId = undefined, questionId = undefined } = {} } =
    useMatch(`/${getConfig().constants.quizUrlPrefix}/:quizId/:questionId/`) ?? {}

  return useMemo(
    () => ({
      params: {
        quiz: quizId as QuizId | undefined,
        question: questionId as QuestionId | undefined,
      },
    }),
    [quizId, questionId]
  )
}
