import { apiGetUser } from "../../api"
import { isSubscriptionInstance } from "../../config"
import type { QuestionId, QuizId } from "../../types"
import { getAmplitudeUserId, setAmplitudeUserId } from "../analytics/useAmplitude"
import { logError } from "../useCaptureException"

export type NavigationDataType = {
  quizId?: QuizId
  questionId?: QuestionId
}

const SESSION_USER_ID_KEY = "USER_ID"

const setUserId = (userId: UserId): void => {
  setAmplitudeUserId(userId)
  window.sessionStorage.setItem(SESSION_USER_ID_KEY, userId)
}

const getQueryUserId = (): UserId | undefined => {
  const parsed = new URLSearchParams(window.location.search)
  const queryUserId = parsed.get("user_id")
  if (queryUserId) {
    return queryUserId as UserId
  }
  return undefined
}

export const getUserId = (): UserId | undefined =>
  getQueryUserId() ??
  getAmplitudeUserId() ??
  (window.sessionStorage.getItem(SESSION_USER_ID_KEY) as UserId | undefined)

// FIXME clear amplitudeUserId too
export const clearUserId = (): void => window.sessionStorage.removeItem(SESSION_USER_ID_KEY)

export const initUserId = async (): Promise<UserId> => {
  let userId: string
  const amplitudeUserId = getAmplitudeUserId()
  let internalUserId = getUserId()
  if (internalUserId && internalUserId.length > 100) {
    /* see https://paltaskincare.atlassian.net/browse/GEN-3650 */
    /* Maybe clear AmplitudeUserId ???  */
    clearUserId()
    internalUserId = undefined
  }

  if (isSubscriptionInstance()) {
    userId = await apiGetUser()
    setUserId(userId as UserId)
  } else if (internalUserId) {
    userId = internalUserId
  } else {
    userId = await apiGetUser()
  }

  if (!amplitudeUserId) {
    setUserId(userId as UserId)
  } else if (userId != amplitudeUserId) {
    logError(`UserId from Amplitude is different / ${JSON.stringify({ userId, amplitudeUserId })}`)
  }

  window?.mixpanel?.identify(userId as UserId)
  // Identify in posthog only on paywall
  return userId as UserId
}
