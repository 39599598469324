import { Box, Heading, BoxProps } from "@chakra-ui/react"
import { TT, TTString, IfTTExists, getTTLength } from "~/components/shared/AttributedString"

type BasePageHeaderOwnProps = {
  title?: TTString
  upTitle?: TTString
  subTitle?: TTString
  subTitleProps?: BoxProps
}
type BasePageHeaderProps = Omit<BoxProps, keyof BasePageHeaderOwnProps> & BasePageHeaderOwnProps

export const BasePageHeader = ({
  title,
  upTitle,
  subTitle,
  subTitleProps = {},
  ...boxProps
}: BasePageHeaderProps) => (
  <Box mt={2} {...boxProps}>
    <IfTTExists if={upTitle}>
      <Box mb={3} textStyle="Subtitle/Primary">
        <TT>{upTitle}</TT>
      </Box>
    </IfTTExists>
    <IfTTExists if={title}>
      <Heading as="h1" size={getTTLength(title) > 85 ? "Header/Secondary" : "Header/Primary"}>
        <TT>{title}</TT>
      </Heading>
    </IfTTExists>
    <IfTTExists if={subTitle}>
      <Box mt={3} textStyle="Paragraph/Primary" textColor="Base/basePrimary" {...subTitleProps}>
        <TT>{subTitle}</TT>
      </Box>
    </IfTTExists>
  </Box>
)
