import { FC, useMemo, ReactNode } from "react"
import { Box, Icon, useMultiStyleConfig } from "@chakra-ui/react"
import { RoundIcon, ChevronIcon, CheckMarkIcon } from "./Icons"

export const ProgressBarItemLayout: FC<{ children: ReactNode; progress: number }> = ({
  children,
  progress,
}) => {
  const progressState = useMemo(
    () => (progress <= 0 ? "inactive" : progress > 0 ? "progress" : "completed"),
    [progress]
  )
  const styles = useMultiStyleConfig("ProgressBarsPage", {
    variant: progressState,
  })
  return (
    <Box __css={{ ...styles["row"], "--progress": String(progress), "--state": progressState }}>
      <Box __css={styles["progressIndicator"]} />
      <Box __css={styles["content"]}>{children}</Box>
    </Box>
  )
}

export const ProgressBarItemWithIcon: FC<{ title: string; progress: number }> = ({
  title,
  progress,
}) => {
  let ShowedIcon = ChevronIcon
  if (progress > 0) {
    ShowedIcon = RoundIcon
  }
  if (progress >= 1) {
    ShowedIcon = CheckMarkIcon
  }
  return (
    <ProgressBarItemLayout progress={progress}>
      <Icon as={ShowedIcon} fontSize="16px" />
      {title}
    </ProgressBarItemLayout>
  )
}
