import { Box, Flex, Heading, Image } from "@chakra-ui/react"
import { TT, TTString } from "~/components/shared/AttributedString"
import { SummaryList, SummaryListItemData } from "~/components/pages/SummaryPage/SummaryList"
import {
  NextButton,
  NextButtonBottomSlideUpContainer as NextButtonContainer,
} from "~/components/shared/NextButton"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"

export const SummaryPage = ({
  header,
  summaryList,
  graphHeader,
  graphImg,
  next,
}: {
  header: TTString
  summaryList: SummaryListItemData[]
  graphHeader: TTString
  graphImg: string
  next: () => void
}) => {
  return (
    <BasePageWrapper justifyContent="space-between">
      <Box>
        <Heading as="h1" size="h1" textAlign="center" mb={6}>
          <TT>{header}</TT>
        </Heading>
        <Box mb={6}>
          <SummaryList list={summaryList} />
        </Box>
        <Flex
          mb={1}
          bgColor="Base/neutralSecondary"
          borderRadius="28px"
          paddingX={1}
          paddingBottom={1}
          flexDirection="column"
        >
          <Box pt={4} pb={3} px={8} textStyle="MediumSubtitleTertiary" textAlign="center">
            <TT>{graphHeader}</TT>
          </Box>
          <Image borderRadius="3xl" src={graphImg} title="pora advantages" />
        </Flex>
      </Box>
      <NextButtonContainer visible>
        <NextButton onClick={next} />
      </NextButtonContainer>
    </BasePageWrapper>
  )
}
