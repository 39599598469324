import { Box, Text, Flex, ChakraProps, SystemStyleObject } from "@chakra-ui/react"
import { FC } from "react"
import { useProgress } from "~/hooks/useProgress"
import { LOCALE, noop } from "~/utils"

type ScaleProps = {
  title: string
  maxPercent: number
  color: ChakraProps["color"]
}

/* use from utils */
const formatPercent = (num: number) => {
  return new Intl.NumberFormat(LOCALE, {
    style: "percent",
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  }).format(num / 100)
}

const duration = 2

/* TODO replace by <ProgressBar/> */
export const Scale: FC<ScaleProps> = ({ maxPercent, title, color }) => {
  const { mapped: percent } = useProgress({
    delay: 0,
    duration,
    onComplete: noop,
    map: (progress) => maxPercent * progress,
  })

  const pseudoElementStyle: SystemStyleObject = {
    content: '" "',
    display: "block",
    position: "absolute",
    height: "100%",
    bgColor: color,
    borderRadius: "full",
  }

  return (
    <Box>
      <Text textStyle="Paragraph/Primary" color="Base/basePrimary">
        {title}
      </Text>
      <Flex alignItems="center">
        <Box
          width="100%"
          height="6px"
          position="relative"
          marginRight="4px"
          _before={{
            ...pseudoElementStyle,
            width: "100%",
            opacity: 0.2,
          }}
          _after={{
            ...pseudoElementStyle,
            width: `${percent}%`,
          }}
        />

        <Text
          textStyle="Header/Quaternary"
          color="Base/basePrimary"
          minWidth="36px"
          textAlign="right"
          flexShrink={0}
        >
          {formatPercent(percent ?? 0)}
        </Text>
      </Flex>
    </Box>
  )
}
