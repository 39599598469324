import { FC } from "react"
import { Box, BoxProps } from "@chakra-ui/react"
import { VFlex } from "~/components/shared/VFlex"

export const SectionDivider: FC<BoxProps> = () => (
  <VFlex gap={2} bgColor="Base/neutralSecondary" w="full">
    <Box w="full" h={6} borderTopRadius={0} borderBottomRadius="3xl" bgColor="white" />
    <Box w="full" h={6} borderTopRadius="3xl" borderBottomRadius={0} bgColor="white" />
  </VFlex>
)
