import { FC } from "react"
import { Page } from "../../shared/Page/Page"
import { NextButton } from "../../shared/NextButton/NextButton"
import type { NextPageCb } from "../../../hooks/useNextQuiz"

export const Page404: FC<{
  next: NextPageCb
  type: string | undefined
  debugData: unknown | undefined
}> = ({ next, type = "UNKNOWN", debugData }) => {
  return (
    <Page className="Page404">
      <Page.Title>
        Page of type <code>{type}</code> is not resolved
      </Page.Title>
      <Page.Content>
        <pre>{typeof debugData !== "undefined" && JSON.stringify(debugData, undefined, 2)}</pre>
      </Page.Content>
      <Page.Bottom>
        <NextButton onClick={next} />
      </Page.Bottom>
    </Page>
  )
}
