import { FC } from "react"
import { NextPageCb } from "~/hooks/useNextQuiz"
import { Box, Flex, Heading, Image } from "@chakra-ui/react"
import {
  NextButton,
  NextButtonBottomSlideUpContainer as NextButtonContainer,
} from "~/components/shared/NextButton"
import { TT, TTString } from "~/components/shared/AttributedString"

export const PrePaywallMessagePage: FC<{
  title: TTString
  image: string
  next: NextPageCb
}> = ({ title, image, next: onNext }) => {
  return (
    <>
      <Flex
        direction="column"
        alignItems="flex-start"
        paddingTop="64px"
        paddingBottom="88px"
        marginX={6}
      >
        <Box>
          <Image src={image} />
        </Box>
        <Heading textAlign="center" mt={6} size="MediumHeaderPrimary">
          <TT>{title}</TT>
        </Heading>
      </Flex>
      <NextButtonContainer visible>
        <NextButton onClick={onNext} />
      </NextButtonContainer>
    </>
  )
}
