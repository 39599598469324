import { FC } from "react"
import { TT, TTString } from "~/components/shared/AttributedString"
import { Box, Center, Flex, Spinner } from "@chakra-ui/react"
import { LoviLogoIcon } from "~/components/shared/WelcomeBlur"
import videoSrc from "./assets/animation.mp4?url"
import imageSrc from "./assets/animation.webp?url"
import { useProgress } from "~/hooks/useProgress"
import { NextPageCb } from "~/hooks/useNextQuiz"

const Video = ({ src, imageSrc }: { src: string; imageSrc: string }) => (
  <video muted loop playsInline autoPlay src={src} poster={imageSrc}>
    <img src={imageSrc} alt="" title="" />
  </video>
)

const LOADING_DURATION_SEC = 5
const LOADING_DELAY_SEC = 0.3

const ProgressBar: FC<{
  onComplete: () => void
  duration: number
  delay: number
}> = ({ onComplete, duration, delay }) => {
  const { progress100 } = useProgress({
    delay,
    duration,
    onComplete,
  })
  return (
    <>
      <Flex
        bgColor="Base/accentPrimaryBg2"
        h="8px"
        borderRadius="100px"
        pos="relative"
        alignItems="center"
        justifyContent="center"
        width="full"
      >
        <Flex
          top={0}
          left={0}
          right={0}
          bottom={0}
          width={`calc(${progress100}%)`}
          pos="absolute"
          backgroundColor={"Base/accentPrimary"}
          borderRadius="100px"
        />
      </Flex>
    </>
  )
}

export const WelcomeSimpleLoadingPage: FC<{
  title: TTString
  loadingTitle: TTString
  loadingSubTitle: TTString
  next: NextPageCb
}> = ({ title, loadingTitle, loadingSubTitle, next }) => {
  return (
    <Flex bg="white" direction="column" flexGrow={1} alignItems="center" padding={6}>
      <Center mb={6}>
        <LoviLogoIcon width="47px" height="24px" color="black" />
      </Center>
      <Box mt={3} textStyle="Subtitle/Primary" textAlign="center">
        <TT>{title}</TT>
      </Box>
      <Box mt={8}>
        <Video src={videoSrc} imageSrc={imageSrc}></Video>
      </Box>
      <Box mt={6} mb={2} textStyle="Subtitle/Secondary" textAlign="center">
        <TT>{loadingTitle}</TT>
      </Box>
      <Flex w="full" mb={2}>
        <ProgressBar delay={LOADING_DELAY_SEC} duration={LOADING_DURATION_SEC} onComplete={next} />
      </Flex>
      <Box mb={6} textStyle="Paragraph/Secondary" textAlign="center">
        <TT>{loadingSubTitle}</TT>
      </Box>
      <Box color="Base/baseSecondary" textStyle="Paragraph/Secondary">
        Lóvi Skincare & Routine Tracker: AI-powered personal skincare insights, cutting-edge
        analytical tools, and daily educational articles to keep you informed and glowing.
        <br />
        <br />
        Results may vary based on your skin type, current habits, and effort. Users on the premium
        plan may expect to see visible improvements in skin texture and radiance within a few weeks.
      </Box>
    </Flex>
  )
}
