import { createIcon } from "@chakra-ui/react"

export const CheckIcon = createIcon({
  viewBox: "0 0 20 20",
  defaultProps: {
    width: "20px",
    height: "20px",
    fill: "none",
  },
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3914 0.000201714C13.3585 -0.0169426 16.3055 1.05828 18.1569 3.4175C20.0096 5.77837 20.3283 8.92796 19.7177 11.8845C19.0915 14.916 17.6254 17.8637 14.8915 19.2267C12.1387 20.5992 8.9357 19.9832 6.1694 18.639C3.38964 17.2884 0.889077 15.1403 0.196095 12.0863C-0.498668 9.02448 0.724251 5.89112 2.73571 3.50661C4.65334 1.23333 7.44714 0.0172141 10.3914 0.000201714Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M6.42847 9.66833C8.2652 12.7908 9.33663 13.5714 9.64275 13.5714L13.5458 7.14282"
        stroke="white"
        strokeWidth="1.42857"
        strokeLinecap="round"
      />
    </>
  ),
})

export const StarIcon1 = createIcon({
  viewBox: "0 0 20 20",
  defaultProps: {
    width: "20px",
    height: "20px",
    fill: "none",
  },
  path: (
    <>
      <g opacity="0.6">
        <path
          d="M19.6315 5.76157C15.7634 5.36658 15.0148 2.33167 14.7197 0.0610153C14.8386 1.92706 14.9204 5.31737 10.507 5.56729C12.2985 5.9114 14.4257 7.99541 14.8737 11.9127C15.4176 8.39167 16.2102 6.40776 19.6315 5.76157Z"
          fill="white"
        />
        <path
          d="M1.00689 6.97149C1.23794 5.45628 3.51076 5.80286 3.2797 7.31807C3.04865 8.83329 0.775831 8.4867 1.00689 6.97149Z"
          fill="white"
        />
        <path
          opacity="0.7"
          d="M2.06134 15.6658C6.27108 14.1015 6.09562 11.3538 5.7665 8.72962C6.17519 10.8503 7.19927 13.8018 11.7745 12.7345C9.87153 13.6391 7.93019 14.7957 8.56628 19.3052C6.93624 15.5267 6.07381 15.3951 2.06134 15.6658Z"
          fill="white"
        />
      </g>
    </>
  ),
})

export const StarIcon2 = createIcon({
  viewBox: "0 0 20 20",
  defaultProps: {
    width: "20px",
    height: "20px",
    fill: "none",
  },
  path: (
    <>
      <g opacity="0.6">
        <path
          d="M1.2515 7.96372C5.83342 8.29954 7.36392 4.90389 8.20101 2.3087C7.65678 4.46696 6.824 8.41586 11.8993 9.61851C9.74212 9.6515 7.38773 10.0717 6.01458 14.5616C6.14854 10.3307 5.08759 9.42529 1.2515 7.96372Z"
          fill="white"
        />
        <path
          d="M17.0617 7.14763C17.0003 5.58751 14.6601 5.67964 14.7215 7.23976C14.7829 8.79988 17.1231 8.70775 17.0617 7.14763Z"
          fill="white"
        />
        <path
          opacity="0.7"
          d="M11.8571 16.9304C11.784 15.073 8.99779 15.1827 9.07092 17.0401C9.14405 18.8976 11.9302 18.7879 11.8571 16.9304Z"
          fill="white"
        />
      </g>
    </>
  ),
})
