import { FC, useCallback, useMemo } from "react"
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  SimpleGrid,
  createIcon,
  Link,
  LinkProps,
} from "@chakra-ui/react"
import { BgImage, AllVariantButton, LoviLogoIcon } from "~/components/shared/WelcomeBlur"
import { TT, TTString } from "~/components/shared/AttributedString"

import type { VariantProperties } from "~/generated/interview_service"

type WelcomeSelectPageProps = {
  title: TTString
  description: TTString
  image: string
  next: (answers: string[]) => void
  variants: VariantProperties[]
  selectTitle: TTString
}

const DownIcon = createIcon({
  viewBox: "0 0 25 24",
  defaultProps: {
    width: "25px",
    height: "24px",
    fill: "none",
  },
  path: (
    <path
      d="M12.5 16.5234C12.2969 16.5234 12.1172 16.4453 11.9688 16.2969L5.92188 10.1094C5.78906 9.96875 5.71094 9.79688 5.71094 9.60156C5.71094 9.19531 6.01562 8.88281 6.42188 8.88281C6.625 8.88281 6.80469 8.96094 6.92969 9.08594L12.5 14.7734L18.0625 9.08594C18.1953 8.96094 18.375 8.88281 18.5703 8.88281C18.9766 8.88281 19.2812 9.19531 19.2812 9.60156C19.2812 9.79688 19.2031 9.96875 19.0703 10.1016L13.0234 16.2969C12.8906 16.4453 12.6953 16.5234 12.5 16.5234Z"
      fill="white"
    />
  ),
})

function WhiteLink(props: LinkProps) {
  return (
    <Link
      textColor="white"
      _active={{ color: "white" }}
      _focus={{ color: "white" }}
      _hover={{ color: "white" }}
      _visited={{ color: "white" }}
      {...props}
    />
  )
}

export const WelcomeLuvlyPage: FC<WelcomeSelectPageProps> = ({
  next,
  image,
  title,
  description,
  variants: _variants,
  selectTitle,
}) => {
  const handleChange = useCallback(
    (selected: string) => {
      next([selected])
    },
    [next]
  )

  /* TODO use reduce here */
  const variants = useMemo(() => _variants.filter((v) => !v.select_all_variants), [_variants])
  const selectAllVariant = useMemo(
    () => _variants.filter((v) => v.select_all_variants)[0],
    [_variants]
  )

  return (
    <>
      <BgImage bgImage={image} />

      <Flex
        h="full"
        pos="relative"
        pt={4}
        pb={6}
        px={6}
        flexDirection="column"
        justifyContent="space-between"
      >
        <Center>
          <LoviLogoIcon />
        </Center>
        <Box>
          <Flex justifyContent="center" flexDirection="column" textAlign="center">
            <Box
              textAlign="center"
              textStyle="Subtitle/Primary"
              mb={4}
              color="white"
              textShadow="0px 2px 6px rgba(12, 35, 48, 0.40), 0px 1px 2px rgba(12, 35, 48, 0.50);"
            >
              <TT>{description}</TT>
            </Box>
            <Heading
              mb={10}
              fontWeight={600}
              textShadow="0px 7px 14px rgba(12, 35, 48, 0.40), 0px 1px 2px rgba(12, 35, 48, 0.50);"
              as="h1"
              size="Header/Secondary"
              textColor="Base/neutralPrimary"
            >
              <TT>{title}</TT>
            </Heading>
            <Box
              textAlign="center"
              mb={1}
              textColor="Base/neutralPrimary"
              textShadow="0px 2px 6px rgba(12, 35, 48, 0.40), 0px 1px 2px rgba(12, 35, 48, 0.50);"
              textStyle="Header/Tertiary"
            >
              <TT>{selectTitle}</TT>
            </Box>
            <Flex mb={3} justifyContent="center">
              <DownIcon />
            </Flex>
            <SimpleGrid columns={2} spacing={[2, 2]} w="full">
              {variants.map((v, i) => {
                return (
                  <Button
                    variant="inverted"
                    padding={2}
                    minW="full"
                    minH="64px"
                    onClick={() => handleChange(v.text)}
                    whiteSpace="pre-line"
                    textStyle="Subtitle/Primary"
                    textColor="Other/Error"
                    borderRadius="12px"
                    boxShadow="0px 6px 12px 0px rgba(12, 35, 48, 0.20), 0px 1px 2px 0px rgba(12, 35, 48, 0.05);"
                    key={i}
                  >
                    {v.text}
                  </Button>
                )
              })}
            </SimpleGrid>
            {selectAllVariant && (
              <AllVariantButton
                mt={5}
                textStyle="Subtitle/Secondary"
                color="white"
                onClick={() => handleChange(selectAllVariant.text)}
                title={selectAllVariant.text}
              />
            )}
          </Flex>
          <Flex
            flexDirection="column"
            alignItems="center"
            mt={4}
            mb={4}
            textColor="white"
            textStyle="Paragraph/Tertiary"
          >
            <Flex>By continuing you agree to our</Flex>
            <Flex textStyle="Subtitle/Hint">
              <WhiteLink target="_blank" href="https://lovi.care/terms/">
                Terms of Use
              </WhiteLink>
              <Box mx={1}>|</Box>
              <WhiteLink target="_blank" href="https://lovi.care/privacy-policy/">
                Privacy Policy
              </WhiteLink>
            </Flex>
          </Flex>
          <Flex
            opacity={0.5}
            mb={6}
            textAlign="center"
            textColor="white"
            textStyle="Paragraph/Tertiary"
          >
            *The result is not guaranteed. Results may vary and are not claimed to represent typical
            results.
          </Flex>
        </Box>
      </Flex>
    </>
  )
}
