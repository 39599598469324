import { Box, chakra, Flex, shouldForwardProp, Tooltip } from "@chakra-ui/react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { CircleIcon, ControllerIcon, ArrowIcon } from "~/components/shared/RangeInput/Icons"
import { useSlider } from "@chakra-ui/react"
import { isValidMotionProp, motion } from "framer-motion"

const tooltipTitle = "Drag the slider\nto choose one you\nagree with more"

const AnimatedBox = chakra(motion.div, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
})

let hintTouched = false

// 1020 is maximum because with this number
// we can divide 1020/3 and 1020/4 and 1020/5 without float number
// 3,4,5 are steps count
const MAX_VALUE = 1020
export const RangeInput = ({
  onChange: handleChange,
  markers,
}: {
  onChange: (val: number) => void
  markers: { title: string; value: number }[]
}) => {
  const [controllerTouched, setControllerTouched] = useState(false)
  const [activeIndex, setActiveIndex] = useState<number>()
  const points = useMemo(() => {
    // 4 points = 3 ranges that's why markers.length-1
    // .--.--.--.
    const stepSize = MAX_VALUE / (markers.length - 1)
    return new Array(markers.length).fill(0).map((_, i) => i * stepSize)
  }, [markers])
  const handleChangeStart = useCallback(
    (_: number) => {
      if (!controllerTouched) {
        setControllerTouched(true)
        hintTouched = true
      }
    },
    [controllerTouched]
  )
  const { getTrackProps, actions, state, getRootProps, getInputProps, getThumbProps } = useSlider({
    min: 0,
    max: MAX_VALUE,
    onChangeStart: handleChangeStart,
    onChangeEnd: handleChangeEnd,
  })

  function handleChangeEnd(value: number) {
    let closestIndex = 0
    let smallestDiff = 1000
    points.forEach((p, i) => {
      // get distance between point and final user value
      const diff = Math.abs(value - p)
      // comparator to find the closest point
      if (diff < smallestDiff) {
        smallestDiff = diff
        closestIndex = i
      }
    })
    const closestPointValue = points[closestIndex]
    if (typeof closestPointValue === "number" && !isNaN(closestPointValue)) {
      actions.stepTo(closestPointValue)
      setActiveIndex(closestIndex)
      const val = markers[closestIndex]?.value
      if (val && handleChange) {
        handleChange(val)
      }
    }
  }
  return (
    <Box cursor="pointer" mb={10} {...getRootProps()}>
      <input {...getInputProps()} hidden />

      <Box h="2px" bgColor="Base/neutralSecondary" {...getTrackProps()}></Box>
      <Flex
        justifyContent="space-between"
        left={0}
        right={0}
        mr={-3}
        ml={-3}
        mt="-12px"
        pos="absolute"
      >
        {markers.map(({ title: marker }, i, arr) => {
          return (
            <Box textAlign={i === 0 ? "left" : i === arr.length - 1 ? "right" : "center"} key={i}>
              <Box
                display="inline-block"
                bgColor="white"
                pr={i === arr.length - 1 ? 0 : "4px"}
                pl={i === 0 ? 0 : "4px"}
              >
                <CircleIcon />
              </Box>
              <Box
                mt={3}
                whiteSpace="pre"
                textColor={
                  activeIndex !== undefined && activeIndex === i
                    ? "Base/accentActive"
                    : "Base/baseSecondary"
                }
                textStyle="Subtitle/Tertiary"
              >
                {marker}
              </Box>
            </Box>
          )
        })}
      </Flex>
      <RangeInputController
        hintTouched={hintTouched}
        controllerTouched={controllerTouched}
        isDragging={state.isDragging}
        getThumbProps={getThumbProps}
      />
    </Box>
  )
}

const TOOLTIP_DELAY = 1000
function RangeInputController({
  hintTouched,
  controllerTouched,
  isDragging,
  getThumbProps,
}: {
  hintTouched: boolean
  controllerTouched: boolean
  isDragging: boolean
  getThumbProps: ReturnType<typeof useSlider>["getThumbProps"]
}) {
  const [canShowTooltip, setCanShowTooltip] = useState(false)
  // todo gavnocode fixme please, please TIMUR
  useEffect(() => {
    if (hintTouched) {
      setCanShowTooltip(false)
    } else {
      const timerId = setTimeout(() => {
        setCanShowTooltip(true)
      }, TOOLTIP_DELAY)

      return () => clearTimeout(timerId)
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {}
  }, [hintTouched])
  return (
    <Tooltip
      hasArrow
      gutter={16}
      bg="Base/basePrimary"
      color="Base/neutralPrimary"
      placement="top"
      isOpen={canShowTooltip}
      label={tooltipTitle}
      textAlign="center"
      whiteSpace="pre"
      size="Subtitle/Primary"
      p={3}
      borderRadius="2xl"
    >
      <Box
        _focusVisible={{
          outline: "none",
        }}
        pos="absolute"
        top={0}
        transition={!isDragging ? "left 300ms ease 0s" : undefined}
        {...getThumbProps()}
      >
        <AnimatedBox
          animate={
            !controllerTouched
              ? {
                  translateX: [-4, 4],
                }
              : { translateX: 0 }
          }
          // @ts-expect-error todo fix
          transition={
            !controllerTouched
              ? {
                  duration: 0.5,
                  ease: "easeInOut",
                  repeat: Infinity,
                  repeatType: "reverse",
                }
              : undefined
          }
        >
          {!controllerTouched && <ArrowIcon />}
          <Box
            display="inline-block"
            borderRadius="3xl"
            bgColor="Base/accentFocused"
            boxShadow="0px 1px 3px rgba(0, 72, 88, 0.07), 0px 4px 20px rgba(0, 72, 88, 0.15)"
          >
            <ControllerIcon />
          </Box>
          {!controllerTouched && <ArrowIcon transform="rotate(180deg)" />}
        </AnimatedBox>
      </Box>
    </Tooltip>
  )
}
