import { FC } from "react"
import { Stack, Box, List, ListItem, ListIcon, Image } from "@chakra-ui/react"
import { MarkIcon } from "~/components/shared/MarkIcon"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"
import {
  NextButton,
  NextButtonBottomSlideUpContainer as NextButtonContainer,
} from "~/components/shared/NextButton"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import type { AboutProgramScreen } from "~/generated/interview_service"
import type { NextPageCb } from "~/hooks/useNextQuiz"
import { TT, AttributedStringType } from "~/components/shared/AttributedString"

interface AboutProgramProps extends AboutProgramScreen {
  next: NextPageCb
}

const getListItems = (description: AttributedStringType): AttributedStringType[] => {
  const listItems: AttributedStringType[] = []
  let currentListItem: AttributedStringType = { parts: [] }

  for (const part of description.parts) {
    if (part.content.startsWith("\n")) {
      listItems.push(currentListItem)

      currentListItem = {
        parts: [
          {
            content: part.content.slice(1),
            format: part.format,
          },
        ],
      }
    } else {
      currentListItem.parts.push(part)
    }
  }

  listItems.push(currentListItem)

  return listItems
}

export const AboutProgram: FC<AboutProgramProps> = ({
  title,
  description,
  image,
  subtitle,
  next,
}) => {
  const listItems = description ? getListItems(description) : []

  return (
    <BasePageWrapper paddingTop={5}>
      <Image src={image} />
      <BasePageHeader mt={0} mb={8} title={title} />
      <Stack justify="flex-start" align="flex-start" spacing={3} mb={5}>
        <Box textStyle="Subtitle/Primary">{subtitle}</Box>
        <List spacing={0} textStyle="Paragraph/Primary" mt={0}>
          {listItems.map((item, index) => (
            <ListItem display="inline-flex" alignItems="center" key={index}>
              <ListIcon as={MarkIcon} boxSize={8} p={0.5} mr={1} color="Base/accentSecondary" />
              <TT>{item}</TT>
            </ListItem>
          ))}
        </List>
      </Stack>
      <NextButtonContainer visible>
        <NextButton onClick={next} />
      </NextButtonContainer>
    </BasePageWrapper>
  )
}
