import { FC } from "react"
import { Image, ImageProps } from "@chakra-ui/react"
import type { Image as PresentationImageType } from "~/generated/presentation"
export type { Image as PresentationImageType } from "~/generated/presentation"

const getImageSrc = ({ source }: PresentationImageType): string | undefined => {
  if (source?.$case === "url") {
    return source.url
  }
  if (source?.$case === "base64_data") {
    return `data:;base64,${source.base64_data}`
  }
  return undefined
}

export const PresentationImage: FC<{ source: PresentationImageType } & ImageProps> = ({
  source,
  ...rest
}) => <Image src={getImageSrc(source)} {...rest} />
