import { Flex, FlexProps } from "@chakra-ui/react"

export const BasePageWrapper = (props: FlexProps) => (
  <Flex
    w="full"
    //minH="100vh" TODO check this
    flexDirection="column"
    justifyContent="flex-start"
    paddingTop="64px"
    paddingBottom="88px"
    paddingX={6}
    {...props}
  />
)
