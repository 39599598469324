import { FC, ReactNode, useEffect } from "react"
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  Heading,
  Image,
  Box,
} from "@chakra-ui/react"
import { noop } from "~/utils"
import { PreloadedStatic } from "~/static_images_to_preload"
import { useAmplitude } from "~/hooks/analytics/useAmplitude"

const ModalWrapper: FC<{ children: ReactNode; isOpen: boolean; onClose: () => void }> = ({
  children,
  isOpen = false,
  onClose = noop,
}) => {
  return (
    <Modal isOpen={true} onClose={onClose}>
      {isOpen ? <ModalOverlay /> : null}

      <Box visibility={isOpen ? "visible" : "hidden"}>
        <ModalContent
          bg="white"
          borderRadius="brand24"
          paddingX={6}
          paddingTop={16}
          paddingBottom={6}
          maxWidth="327px"
        >
          <ModalCloseButton borderRadius="full" color="Base/baseSecondary" />
          {children}
        </ModalContent>
      </Box>
    </Modal>
  )
}
export const FaceScannerPushModal: FC<{
  onClose: () => void
  isOpen: boolean
  children: ReactNode
}> = ({ onClose, isOpen, children }) => {
  const { logFaceScanPushModalView } = useAmplitude()

  useEffect(() => {
    if (isOpen) {
      logFaceScanPushModalView()
    }
  }, [logFaceScanPushModalView, isOpen])

  return (
    <ModalWrapper onClose={onClose} isOpen={isOpen}>
      <Heading mb={2} textAlign="center" size="Header/Secondary">
        Did you know?
      </Heading>
      <Box mb={6} textAlign="center" textStyle="Paragraph/Primary" mt={2}>
        Users using Lóvi&apos;s Skin Analysis
        <br />
        <Box as="span" fontWeight="bold" color="Other/Green">
          see results 47%&nbsp;faster
        </Box>
      </Box>
      <Box mb={3}>
        <Image src={PreloadedStatic.FaceScannerPushImage} />
      </Box>
      {children}
    </ModalWrapper>
  )
}
