import { FC, ReactNode } from "react"
import { Box, Flex, IconButton, Portal } from "@chakra-ui/react"
import { useNavigateBack } from "@poraai/shared/useNavigateWithSearchParams"

import { useSelector } from "~/hooks/redux"
import { useNavigateWithSearchParams } from "~/hooks/useNavigateWithSearchParams"
import { useQuizHistory } from "~/hooks/useQuizHistory"
import { getProgressBarTheme, getProgressIndicator } from "~/store/survey/selectors"
import { useQuestionIdFromUrl } from "~/hooks/useQuestionIdFromUrl"
import { useAmplitude } from "~/hooks/analytics/useAmplitude"
import { useGoogleAnalytics } from "~/hooks/analytics/useGoogleAnalytics"
import { useQuizIdFromUrl } from "~/hooks/useQuizIdFromUrl"
import type { ProgressBarTheme } from "~/api/QuestionType"

import { useIsLoviTheme } from "~/theme/themeProvider"

import { CrossIcon } from "./Icons"
import topBgSrc from "./assets/topBg.svg?url"

const BackIcon: FC = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
    <path
      d="M23 13L16 20L23 27"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const Logo: FC = () => (
  <svg width="55" height="20" fill="none" style={{ marginTop: "4px" }}>
    <path
      d="M4.477 13.936c.78.64 2.31 1.447 4.36 1.447 4.907 0 7.91-3.422 7.91-7.733C16.747 3.17 13.687 0 9.01 0 6.556 0 5.054.918 4.275 1.67V.25H.608V20h3.869v-6.064zm-.115-6.23c0-2.532 1.616-4.395 4.243-4.395 2.57 0 4.273 1.863 4.273 4.394 0 2.532-1.617 4.368-4.244 4.368-2.57 0-4.272-1.836-4.272-4.368zM17.85 7.733c0 4.395 3.233 7.65 8.054 7.65 4.764 0 8.084-3.338 8.084-7.705C33.988 3.283 30.783 0 25.904 0c-4.648 0-8.055 3.31-8.055 7.733zm3.868-.028c0-2.503 1.645-4.339 4.186-4.339 2.483 0 4.215 1.864 4.215 4.34 0 2.475-1.645 4.311-4.186 4.311-2.483 0-4.215-1.808-4.215-4.312zM42.524.195c-1.588 0-2.454.5-3.002 1.252V.25h-3.87v14.881h3.87V6.064c0-1.28.52-1.975 2.02-1.975h.982V.195zM43.028 10.265c0 3.087 2.28 5.062 5.37 5.062 1.79 0 2.829-.695 3.262-1.14v.945h3.233V5.758c0-3.31-1.386-5.674-5.832-5.674-3.637 0-5.398 1.864-5.485 4.673h3.609c0-.834.433-1.725 1.876-1.725 1.444 0 2.021.918 2.021 2.198v.75c-.404-.25-1.357-.611-2.742-.611-3.205 0-5.313 2.114-5.313 4.896zm3.637-.028c0-1.196.895-2.114 2.223-2.114 1.3 0 2.252.862 2.252 2.114 0 1.252-.953 2.142-2.223 2.142-1.328 0-2.252-.89-2.252-2.142z"
      fill="#00C0EB"
    />
  </svg>
)

export const BackButton: FC<{ theme: ProgressBarTheme }> = ({ theme }) => {
  //TODO:implement single way for tracking url for back and next button
  const { logPrevQuiz } = useAmplitude()
  const questionId = useQuestionIdFromUrl()
  const navigateBack = useNavigateBack()
  const { track } = useGoogleAnalytics()

  const onBackButtonClick = () => {
    track("click", { Back_quiz: questionId ?? "" })
    logPrevQuiz()
    navigateBack()
  }

  return (
    <IconButton
      color={theme === "plain" ? "white" : "Base/basePrimary"}
      visibility="visible"
      aria-label="Back"
      onClick={onBackButtonClick}
      icon={<BackIcon />}
      variant="link"
    />
  )
}

const transformProgressIndicator = (
  sections: number,
  active_section: number,
  active_section_progress: number
): number[] => {
  const result = new Array(sections)
  result.fill(1, 0, active_section).fill(0, active_section)
  result[active_section] = active_section_progress
  return result
}

const useProgressBarData = () => {
  const quizId = useQuizIdFromUrl()
  const questionId = useQuestionIdFromUrl()
  const progressBarIndicator = useSelector(getProgressIndicator(quizId!, questionId!))
  if (!progressBarIndicator) {
    return null
  }
  const parts: number[] = transformProgressIndicator(
    progressBarIndicator.sections_count,
    progressBarIndicator.active_section_index,
    progressBarIndicator.active_section_progress
  )
  return parts
}
const transition = "width 0.3s ease-in-out"
const ProgressBarPart: FC<{
  flex: number
  progress: number
  theme: ProgressBarTheme
  isFirst?: boolean
}> = ({ flex, progress, theme, isFirst = false }) => {
  const isLoviTheme = useIsLoviTheme()
  const bgColor =
    theme === "blue"
      ? isLoviTheme
        ? "Base/neutralDisabled"
        : "Base/neutralSecondary"
      : "extra.WhiteAlpha50"
  const fillColor = theme === "blue" ? "Base/accentPrimary" : "white"

  return (
    <Box flex={flex} marginLeft={isFirst ? 0 : "4px"} h="3px" transition={transition}>
      <Box
        h="100%"
        borderRadius="full"
        bgColor={progress === 1 ? fillColor : bgColor}
        width="100%"
        transition={transition}
      />
      {progress !== 0 && progress !== 1 && (
        <Box
          h="100%"
          borderRadius="full"
          bgColor={fillColor}
          width={`${progress * 100}%`}
          position="relative"
          top="-100%"
          transition={transition}
        />
      )}
    </Box>
  )
}

const Progress: FC<{ theme: ProgressBarTheme; parts: number[] }> = ({ theme, parts }) => {
  return (
    <Flex flex="1" w="100%" marginX={{ base: 4, lg: 8 }} alignItems="center">
      {parts.map((progress, i) => {
        const isActive = progress !== 0 && (i + 1 === parts.length || parts[i + 1] === 0)
        return (
          <ProgressBarPart
            theme={theme}
            key={i}
            flex={isActive ? 3 : 1}
            progress={progress}
            isFirst={i === 0}
          />
        )
      })}
    </Flex>
  )
}

const ProgressBarWrapper: FC<{ theme: ProgressBarTheme; children: ReactNode }> = ({
  theme,
  children,
}) => {
  let rest = {}

  if (theme === "plain") {
    rest = {
      bgGradient: `linear-gradient(180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.1) 49.2%,
      rgba(0, 0, 0, 0.04) 75%,
      rgba(0, 0, 0, 0) 100%) `,
    }
  }

  if (theme === "back_close_plain") {
    rest = {
      filter: "invert(100%)",
    }
  }

  return (
    <Flex
      id="navigation_progressbar"
      direction="row"
      h={14}
      w="100%"
      paddingX={4}
      position="absolute"
      justifyContent="space-between"
      alignItems="center"
      zIndex={1000}
      {...rest}
    >
      {children}
    </Flex>
  )
}

export const Navigation: FC = () => {
  const {
    params: { quiz: quizId, question: questionId },
  } = useQuizHistory()
  const progressBarData = useProgressBarData()
  const theme = useSelector(getProgressBarTheme(quizId!, questionId!))
  const navigate = useNavigateWithSearchParams()

  const handleCrossClick = () => {
    navigate("/")
  }

  if (theme === "none") {
    return null
  }

  return (
    <ProgressBarWrapper theme={theme}>
      {theme !== "gradient" && <BackButton theme={theme} />}
      {progressBarData && (theme === "plain" || theme === "blue") && (
        <Progress theme={theme} parts={progressBarData} />
      )}
      {theme === "logo" && <Logo />}
      {(theme === "gradient" || theme === "back_gradient") && (
        <Portal>
          <Box
            position="fixed"
            top={0}
            overflow="visible"
            bgImage={topBgSrc}
            bgPosition="top center"
            bgRepeat="no-repeat"
            height="200px"
            width="510px"
            left="calc(50% - 510px / 2)"
            zIndex={-1}
          />
        </Portal>
      )}
      <Box w="40px" /* Spacer with same width as BackButton */>
        {(theme === "back_close" || theme === "back_close_plain") && (
          <Box
            cursor="pointer"
            onClick={handleCrossClick}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CrossIcon />
          </Box>
        )}
      </Box>
    </ProgressBarWrapper>
  )
}
