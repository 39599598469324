import { FC } from "react"
import { ProgressBarsPageProps, ProgressBarsPage } from "./ProgressBarsPage"
import { ReviewsList } from "./ReviewItem"
import { reviews } from "./data"

type ProgressBarsWithReviewsPageProps = Omit<ProgressBarsPageProps, "children">
export const ProgressBarsWithReviewsPage: FC<ProgressBarsWithReviewsPageProps> = (props) => (
  <ProgressBarsPage {...props}>
    <ReviewsList
      list={reviews}
      mt={10}
      w="calc(100% + 2 * var(--chakra-space-6))"
      marginX={-6}
      paddingX={6}
    />
  </ProgressBarsPage>
)
