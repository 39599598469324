import { FC } from "react"
import { Flex, Heading, Spinner } from "@chakra-ui/react"
import { useProgress } from "~/hooks/useProgress"
import { BlurBox, WelcomeBlur } from "~/components/shared/WelcomeBlur/WelcomeBlur"
import { TT, TTString } from "~/components/shared/AttributedString"
import { useIsLoviTheme } from "~/theme/themeProvider"

const LOADING_DURATION_SEC = 5
const LOADING_DELAY_SEC = 0.3

const ProgressBar: FC<{
  onComplete: () => void
  duration: number
  delay: number
}> = ({ onComplete, duration, delay }) => {
  const { progress100 } = useProgress({
    delay,
    duration,
    onComplete,
  })
  const isLoviTheme = useIsLoviTheme()
  const visualPercentage = Math.floor(progress100)
  const changeTitleColor = progress100 > 48

  return (
    <>
      <Flex
        mb={2.5}
        alignItems="center"
        justifyContent="center"
        textStyle="Subtitle/Tertiary"
        textColor="Base/neutralPrimary"
      >
        Loading the quiz <Spinner size="sm" ml={1.5} color="Base/neutralPrimary" />
      </Flex>
      <Flex
        bgColor="Base/neutralPrimary"
        h="40px"
        borderRadius="2xl"
        pos="relative"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          top={1}
          left={1}
          right={1}
          bottom={1}
          width={`calc(${progress100}% - 8px)`}
          pos="absolute"
          backgroundColor={isLoviTheme ? "Base/accentSecondary" : "Base/accentPrimary"}
          borderRadius="xl"
        />
        <Flex
          zIndex={1}
          textStyle="Subtitle/Primary"
          transition="color 200ms"
          textColor={changeTitleColor ? "Base/neutralPrimary" : "Base/basePrimary"}
        >
          {visualPercentage} %
        </Flex>
      </Flex>
    </>
  )
}

export const WelcomeLoadingPage: FC<{
  title: TTString
  description: TTString
  image: string
  next: () => void
}> = ({ next, image, title, description }) => {
  const isLoviTheme = useIsLoviTheme()

  return (
    <WelcomeBlur image={image}>
      <Flex
        justifyContent="center"
        flexDirection="column"
        textAlign={isLoviTheme ? "left" : "center"}
      >
        <BlurBox mb={{ base: 2, lg: 5 }} alignSelf={isLoviTheme ? "flex-start" : "center"}>
          {description}
        </BlurBox>
        <Heading mb={8} as="h1" size="Header/Primary" textColor="Base/neutralPrimary">
          <TT>{title}</TT>
        </Heading>
        <ProgressBar duration={LOADING_DURATION_SEC} delay={LOADING_DELAY_SEC} onComplete={next} />
      </Flex>
    </WelcomeBlur>
  )
}
