import { ChangeEvent, FC, useCallback, useMemo, useState } from "react"
import { Box, Button, FormControl, FormErrorMessage, Heading, Icon, Input } from "@chakra-ui/react"
import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"

import { IfTTExists, TT, TTString } from "~/components/shared/AttributedString"
import { NextButton } from "~/components/shared/NextButton"
import { VFlex } from "~/components/shared/VFlex"
import { NextPageCb } from "~/hooks/useNextQuiz"
import { formatDate } from "~/utils/formatDate"

import ValidationIcon from "./validationIcon.svg?react"

dayjs.extend(isBetween)
const TODAY = dayjs().startOf("day")
const TOMORROW = dayjs().startOf("day").add(1, "day")
const minMax = {
  BEFORE: [TODAY.add(-100, "years"), TODAY],
  AFTER: [TOMORROW, TODAY.add(100, "years")],
  UNKNOWN: [TODAY.add(-100, "years"), TODAY.add(100, "years")],
} as const
const dateFormat = formatDate("FULL_LONG")

type ValidationResult = [boolean, string]
const validation = (minMax: readonly [dayjs.Dayjs, dayjs.Dayjs], v: string): ValidationResult => {
  if (dayjs(v).isBetween(minMax[0], minMax[1], "day", "[]")) {
    return [true, ""]
  } else {
    return [
      false,
      /* eslint-disable-next-line no-irregular-whitespace */
      `Please enter a date after ${dateFormat(minMax[0].toDate())} and before ${dateFormat(
        minMax[1].toDate()
      )}`,
    ]
  }
}
type Variant = "BEFORE" | "AFTER" | "UNKNOWN"
export const DateInputPage: FC<{
  title: TTString
  description: TTString
  next: NextPageCb
  variant: Variant
}> = ({ title, description, next, variant = "UNKNOWN" }) => {
  const [inputValue, setInputValue] = useState<string | null>(null)
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined)
  const [validationMessage, setValidationMessage] = useState("")
  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      const [isValid, validationMsg] = validation(minMax[variant], value)
      setIsValid(isValid)
      setValidationMessage(validationMsg)
      setInputValue(value)
    },
    [validation]
  )
  const nextAvailable = useMemo(() => Boolean(inputValue) && isValid, [inputValue, isValid])
  const onSubmit = useCallback(() => {
    const result = inputValue ?? ""
    next([result])
  }, [next, inputValue])
  const onSkip = useCallback(() => {
    next(["skip"])
  }, [next])

  return (
    <VFlex h="full" justifyContent="space-between" marginTop="72px">
      <VFlex alignItems="flex-start" gap={8} paddingX={6}>
        <VFlex gap={3}>
          <IfTTExists if={title}>
            <Heading as="h1" size="Header/Primary">
              <TT>{title}</TT>
            </Heading>
          </IfTTExists>
          <IfTTExists if={description}>
            <Box textStyle="Paragraph/Primary">
              <TT>{description}</TT>
            </Box>
          </IfTTExists>
        </VFlex>
        <VFlex as="form" w="100%" gap={3}>
          <FormControl isInvalid={isValid === false}>
            <Input
              placeholder=""
              min={minMax[variant][0].format("YYYY-MM-DD")}
              max={minMax[variant][1].format("YYYY-MM-DD")}
              required={true}
              type="date"
              onChange={onInputChange}
              variant="outline"
              textStyle="Paragraph/Primary"
              paddingY="18px"
              paddingX={5}
              height="58px" // FIX for safari
              size="lg"
              lineHeight="20px"
              borderRadius="full"
              bg="white"
              borderWidth="1px"
              borderColor="Base/baseTertiary"
              errorBorderColor="Other/Error"
              focusBorderColor="Base/accentPrimary"
              _disabled={{
                bg: "Base/neutralSecondary",
                color: "Base/baseDisabled",
              }}
              _focusVisible={{
                zIndex: 1,
                borderColor: "Base/accentPrimary",
              }}
              _invalid={{
                zIndex: 1,
                borderColor: "Other/Error",
              }}
            />

            {validationMessage && (
              <FormErrorMessage marginTop={2} alignItems="baseline">
                <Icon
                  as={ValidationIcon}
                  mr={0.5}
                  h="18px"
                  w="18px"
                  position="relative"
                  top="3px"
                />
                <Box textStyle="Subtitle/Tertiary">{validationMessage}</Box>
              </FormErrorMessage>
            )}
          </FormControl>
        </VFlex>
      </VFlex>
      <VFlex justifyContent="center" textAlign="center" paddingX={6} paddingY={4} gap={2}>
        <NextButton type="submit" isDisabled={!nextAvailable} onClick={onSubmit} />
        <Button onClick={onSkip} variant="inverted">
          Skip
        </Button>
      </VFlex>
    </VFlex>
  )
}
