import { FC, ReactNode } from "react"
import { NextPageCb } from "~/hooks/useNextQuiz"
import {
  Box,
  Center,
  Flex,
  FlexProps,
  Grid,
  GridItem,
  Icon,
  Image,
  ImageProps,
  LinkBox,
} from "@chakra-ui/react"
import {
  NextButton,
  NextButtonBottomSlideUpContainer as NextButtonContainer,
} from "~/components/shared/NextButton"
import { TTString } from "~/components/shared/AttributedString"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"
import { Cross } from "./Icons"
import { noop } from "~/utils"

type Card = { image: string; hint: string; title: string }
type Product = {
  image: string
  description: string
  fit_percentage: number
  category: string
}

const recommendations: Card[] = [
  {
    image: "https://storage.pora.ai/8d621a76853a3834a84ce20df16fb755.webp",
    hint: "100% fit to you",
    title: "Eye Creams",
  },
  {
    image: "https://storage.pora.ai/b4062709511fb98eded39628b28aff94.webp",
    hint: "100% fit to you",
    title: "Exfoliator",
  },
  {
    image: "https://storage.pora.ai/c53e0fa7bfdab34510dd0ecbc86c9a6a.webp",
    hint: "100% fit to you",
    title: "SPFs",
  },
]
const categoryToSuggestion = {
  cleansing: {
    image: "https://storage.pora.ai/de48202fe88b23ea6815d2935dae0b1e.webp",
    hint: "2 min",
    title: "Face Exercise to unlock inner beauty",
  },
  toning: {
    image: "https://storage.pora.ai/aaf3e53ecf4c4e5db548ef44bc5f4553.webp",
    hint: "1 min",
    title: "Face Exercise to restore the power of microbiome",
  },
  moisturizing: {
    image: "https://storage.pora.ai/a0fb49342c7ae4be27995954f26338c8.webp",
    hint: "1 min",
    title: "Face Exercise to hydrate the skin",
  },
} as const

const productToCard = ({ image, description, fit_percentage }: Product): Card => ({
  image,
  hint: `${fit_percentage}% fit to you`,
  title: description,
})

const getSuggestion = (category: string): Card =>
  categoryToSuggestion[category.toLowerCase() as keyof typeof categoryToSuggestion] ||
  categoryToSuggestion.cleansing

const ProductImage: FC<ImageProps> = (props) => (
  <Center w="56px" h="56px" bgColor="white" borderRadius="brand16">
    <Image
      borderRadius="brand16"
      style={{ aspectRatio: "auto" }}
      w="fit-content"
      h="56px"
      {...props}
    />
  </Center>
)
const CardItem: FC<FlexProps> = (props) => (
  <Flex
    borderRadius="brand8"
    gap={3}
    padding={3}
    bgColor="Base/neutralPrimary"
    shadow="LoviShort"
    {...props}
  />
)
const CardItemContent: FC<{
  image: string
  hint: ReactNode
  title: ReactNode
  hintColor: string
}> = ({ image, hint, hintColor, title }) => (
  <>
    <ProductImage src={image} />
    <Flex direction="column" gap={1} flexGrow={1}>
      <Box textStyle="Subtitle/Hint" color={hintColor}>
        {hint}
      </Box>
      <Box textStyle="Subtitle/Tertiary">{title}</Box>
    </Flex>
  </>
)

const DoubleCard: FC<{ title: ReactNode; card1: Card; card2: Card }> = ({
  title,
  card1,
  card2,
}) => (
  <Flex direction="column" gap={1}>
    <Box as="h5" textStyle="Subtitle/Primary">
      {title}
    </Box>
    <Grid gridColumnGap={1} gridTemplateColumns="1fr 1fr">
      <GridItem>
        <CardItem borderLeftRadius="brand24" direction="column" h="100%">
          <CardItemContent hintColor="Other/Green" {...card1} />
        </CardItem>
      </GridItem>

      <Icon
        position="absolute"
        left="calc(50% - 11.5px)"
        as={Cross}
        w="21px"
        h="21px"
        bgColor="Base/neutralSecondary"
        borderRadius="full"
        alignSelf="center"
      />
      <GridItem>
        <CardItem borderRightRadius="brand24" direction="column" h="100%">
          <CardItemContent hintColor="Other/Info" {...card2} />
        </CardItem>
      </GridItem>
    </Grid>
  </Flex>
)

const ProductRecommendationCard: FC<{ card: Card; onClick: () => void }> = ({
  card,
  onClick = noop,
}) => (
  <LinkBox
    display="flex"
    flexDirection="row"
    borderRadius="brand24"
    gap={3}
    padding={3}
    alignItems="center"
    onClick={onClick}
    shadow="LoviShort"
    bgColor="Base/neutralPrimary"
    cursor="pointer"
  >
    <CardItemContent hintColor="Other/Green" {...card} />
    <Box
      textStyle="Subtitle/Hint"
      color="Base/basePrimary"
      borderColor="Base/basePrimary"
      borderRadius="full"
      borderWidth="1px"
      paddingX={3}
      paddingY={1.5}
      textAlign="center"
      minW="98px"
    >
      Check in app
    </Box>
  </LinkBox>
)

export const ProductFitResult2Page: FC<{
  title: TTString
  products: Product[]
  next: NextPageCb
}> = ({ title, products, next: onNext }) => (
  <BasePageWrapper h="full">
    <BasePageHeader mb={6} title={title} />
    <Flex direction="column" gap={4}>
      {products.map((product) => (
        <DoubleCard
          key={product.description}
          title={product.category}
          card1={productToCard(product)}
          card2={getSuggestion(product.category)}
        />
      ))}

      <Flex direction="column" gap={1}>
        <Box textStyle="Subtitle/Primary">More personal product recommendations</Box>
        {recommendations.map((card) => (
          <ProductRecommendationCard key={card.title} card={card} onClick={onNext} />
        ))}
      </Flex>
    </Flex>
    <Box w="full" minH="88px" />

    <NextButtonContainer visible>
      <NextButton onClick={onNext} />
    </NextButtonContainer>
  </BasePageWrapper>
)
