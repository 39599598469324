import { createReducer } from "@reduxjs/toolkit"

import type { QuizType } from "~/api"
import type { QuestionId, QuizId } from "~/types"
import { addQuiz, releaseAnswers, saveAnswer, setStatus, setUserIdUpdated } from "./actions"
import { ActionData } from "~/generated/interview_service"

export type STATUS = "IDLE" | "LOADING" | "ERROR"
const surveyInitState: {
  quizOrder: QuizId[]
  quiz: Record<QuizId, QuizType>
  answers: Record<QuizId, Record<QuestionId, string[]>>
  answersV2: Record<QuizId, Record<QuestionId, ActionData[]>>
  status: STATUS
  userIdUpdated: boolean
} = {
  quizOrder: [],
  quiz: {},
  answers: {},
  answersV2: {},
  status: "IDLE",
  userIdUpdated: false,
}

export const surveyReducer = createReducer(surveyInitState, (builder) => {
  builder.addCase(addQuiz, (state, action) => {
    if (!state.quizOrder.includes(action.payload.id)) {
      state.quizOrder.push(action.payload.id)
    }
    state.quiz[action.payload.id] = action.payload
  })

  builder.addCase(saveAnswer, (state, action) => {
    const { answers, answersV2, quizId } = action.payload

    if (answers) {
      state.answers[quizId] = { ...answers }
    }

    if (answersV2) {
      state.answersV2[quizId] = { ...answersV2 }
    }
  })

  builder.addCase(releaseAnswers, (state) => {
    state.answers = {}
  })

  builder.addCase(setStatus, (state, action) => {
    state.status = action.payload.status
  })

  builder.addCase(setUserIdUpdated, (state) => {
    state.userIdUpdated = true
  })
})
