import { useCallback } from "react"
import { useParams } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"

import { getTestQueryParams } from "~/utils"
import type { ActionData } from "~/generated/interview_service"

import {
  currentAnswers as currentAnswersSelector,
  nextQuestion as nextQuestionSelector,
  isLastQuestionInSurvey,
  getCompleteAction,
  currentAnswersV2 as currentAnswersV2Selector,
} from "../store/survey/selectors"
import type { QuizType } from "../api"
import { saveAnswer, uploadAnswerThunk } from "../store/survey/actions"
import { getConfig } from "../config"
import type { QuestionId } from "../types"

import { useDispatch, useSelector } from "./redux"
import { useAmplitude } from "./analytics/useAmplitude"
import { useUserId, useQuizHistory, buildQuizUrl, buildQuestionUrl } from "./useQuizHistory"
import { useNavigateWithSearchParams } from "./useNavigateWithSearchParams"
import { useCaptureException } from "./useCaptureException"

type ReturnUseNextQuiz = (result?: string[], answersV2?: ActionData[]) => Promise<undefined>
const noop: ReturnUseNextQuiz = () => Promise.resolve(undefined)

const mergeAnswers = <T extends string | object>(
  existing: Record<QuestionId, T[]> = {},
  question: QuestionId,
  newAnswer: T[]
): Record<QuestionId, T[]> => ({
  ...existing,
  [question]: newAnswer,
})

/* TODO move that to redux-actions */
const useNextQuiz = (): ReturnUseNextQuiz => {
  const {
    params: { quiz: quizId, question: questionId },
  } = useQuizHistory() // FIXME: useParams() here

  const nextQuestionData = useSelector(nextQuestionSelector(quizId, questionId))

  const currentAnswers: Record<QuestionId, string[]> = useSelector(currentAnswersSelector(quizId))
  const currentAnswersV2: Record<QuestionId, ActionData[]> = useSelector(
    currentAnswersV2Selector(quizId)
  )
  //const init = useInitialization({ urlReplaceStrategy: "PUSH", direction: "forward" })
  const dispatch = useDispatch()

  const { status } = nextQuestionData
  if (!quizId || status === "no_quiz_fetched" || typeof questionId === "undefined") {
    return noop
  }
  const next = async (result: string[] = [], resultV2: ActionData[] = []) => {
    /* TODO: move to mergeAnswers action */
    const answers = mergeAnswers<string>(currentAnswers, questionId, result)
    const answersV2 = mergeAnswers<ActionData>(currentAnswersV2, questionId, resultV2)

    dispatch(saveAnswer({ quizId, answers, answersV2 }))
    dispatch(uploadAnswerThunk({ quizId, questionId }))

    return undefined
  }

  return next
}

export const runCompletionAction = ({
  completionAction,
  userId,
  login,
  navigate,
}: {
  completionAction: QuizType["on_completion_action"]
  userId: UserId
  login: () => void
  navigate: (url: string) => void
}) => {
  if (completionAction?.$case === "new_test_request") {
    const quizId = completionAction.new_test_request.test_id
    if (quizId) {
      const url = buildQuizUrl(quizId)
      navigate(url)
    }
  }

  if (
    completionAction?.$case === "redirect" ||
    completionAction?.$case === "set_onboarding_completed"
  ) {
    let url = new URL(getConfig().redirect_url)
    let additionalParams: Record<string, string> = {}
    if (completionAction?.$case === "redirect") {
      const {
        redirect: { endpoint = getConfig().redirect_url, params = {} },
      } = completionAction
      url = new URL(endpoint)

      additionalParams = params
    }

    const defaultParams: Record<string, string> = { user_id: userId }
    const testParams = getTestQueryParams()
    const params = {
      ...defaultParams,
      ...testParams,
      ...additionalParams,
    }
    Object.entries(params).forEach(([k, v]) => {
      if (!url.searchParams.has(k)) {
        url.searchParams.append(k, v)
      }
    })
    // FIXME use redirect() or history.push()
    window.location.href = String(url)
  }

  if (completionAction?.$case === "sign_in") {
    login()
  }
}

export const useRunCompletionAction = () => {
  const { quizId } = useParams()
  const { loginWithRedirect } = useAuth0()
  const { logCompleteAction, logInterviewQuizCompleted } = useAmplitude()
  const { userId } = useUserId()
  const navigate = useNavigateWithSearchParams()
  const completionAction = useSelector(getCompleteAction(quizId))

  /* Maybe this must be in uploadAnswerThunk() */
  return useCallback(() => {
    if (completionAction && userId) {
      logCompleteAction(Object.keys(completionAction))
      runCompletionAction({
        completionAction,
        userId,
        login: loginWithRedirect,
        navigate,
      })
      logInterviewQuizCompleted() // TODO: remove this event
    } else {
      console.error("No completion action or userId")
    }
  }, [])
}

export type NextPageCb = (answers?: string[], answersV2?: ActionData[]) => void
export const useNextPage = (): NextPageCb => {
  const next = useNextQuiz()
  const { loginWithRedirect } = useAuth0()
  const { logNextQuiz, logCompleteAction, logInterviewQuizCompleted } = useAmplitude()

  const { userId } = useUserId()
  const {
    params: { quiz: quizId, question: questionId },
  } = useQuizHistory() // FIXME: useParams() here

  const isLastQuestion = useSelector(isLastQuestionInSurvey(quizId, questionId))
  const completionAction = useSelector(getCompleteAction(quizId))
  const nextQuestionData = useSelector(nextQuestionSelector(quizId, questionId))
  const navigate = useNavigateWithSearchParams()
  const captureException = useCaptureException()

  const cb: NextPageCb = (answers, answersV2) => {
    logNextQuiz(answers)

    // TODO: move to uploadAnswerThunk
    next(answers, answersV2)
      ?.then(() => {
        /* TODO useRunCompletionAction() here */
        if (isLastQuestion && completionAction && userId) {
          logCompleteAction(Object.keys(completionAction))
          runCompletionAction({
            completionAction,
            userId,
            login: loginWithRedirect,
            navigate,
          })
          logInterviewQuizCompleted() // TODO: remove this event
        }
        if (
          "questionId" in nextQuestionData &&
          "quizId" in nextQuestionData &&
          quizId === nextQuestionData.quizId
        ) {
          const url = buildQuestionUrl(nextQuestionData.quizId, nextQuestionData.questionId)
          navigate(url)
        }
      })
      ?.catch((err) => {
        captureException(err)
      })
  }

  return cb
}
