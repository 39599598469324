import { createIcon } from "@chakra-ui/react"

export const CircleIcon = createIcon({
  viewBox: "0 0 24 24",
  defaultProps: {
    fontSize: 24,
  },
  path: (
    <path
      d="M11.2582 1.00267C16.3195 0.89475 20.7964 4.06855 22.3734 8.59473C23.9784 13.2014 22.4573 18.3216 18.3242 21.1311C14.2677 23.8884 8.67444 23.5069 4.62571 20.5624C1.06448 17.9725 0.226193 13.3494 1.66906 9.06681C3.1541 4.65907 6.66606 1.10059 11.2582 1.00267Z"
      fill="white"
      stroke="#ABBBCC"
      strokeWidth="2"
    />
  ),
})

export const ControllerIcon = createIcon({
  viewBox: "0 0 40 40",
  defaultProps: {
    fontSize: 40,
    fill: "none",
  },
  path: (
    <>
      <rect width="40" height="40" rx="20" fill="#0CA0C2" />
      <path
        d="M21.1384 1.00352C29.2613 1.15531 35.7402 7.33738 38.1846 15.1788C40.454 22.4587 37.8913 30.2661 31.6726 34.8263C25.0928 39.6512 16.2645 40.5868 9.67877 36.0741C2.59254 31.2184 -0.723248 22.4787 1.88851 14.4776C4.54219 6.34811 12.4142 0.840497 21.1384 1.00352Z"
        fill="#00C0EB"
        stroke="#00C0EB"
        strokeWidth="2"
      />
      <path
        d="M15 15.5177V24.4816M20 15V25M25 15.9251L25 24.0751"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </>
  ),
})

export const ArrowIcon = createIcon({
  viewBox: "0 0 14 10",
  defaultProps: {
    width: "14px",
    height: "10px",
    fill: "none",
  },
  path: (
    <path
      d="M13 5C7.00001 5 1.68384 5.49871 2.5 5C3.06292 4.65604 9.41241 1.63898 10.0627 1.16739C10.3397 0.966535 10.1417 0.986403 9.87235 1.02496C8.59125 1.20833 7.32085 1.83378 6.12287 2.31396C4.98723 2.76916 3.84828 3.21687 2.72692 3.70979C2.38271 3.8611 2.04474 4.01398 1.72751 4.22255C1.58005 4.3195 1.04617 4.61049 1.00006 4.82788C0.986072 4.8938 3.59417 6.1785 3.69913 6.23795C5.21476 7.09637 7.46258 8.18211 9.87235 9"
      stroke="#0C2330"
      strokeWidth="2"
      strokeLinecap="round"
    />
  ),
})
