import { FC, useEffect } from "react"
import { createRoot } from "react-dom/client"
import {
  useParams,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  createBrowserRouter as originalCreateBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom"
import { Provider as ReduxProvider } from "react-redux"
import { Global } from "@emotion/react"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

import { apiGetConfig, sendMarketingAttributes } from "./api"
import { getConfig, setupConfig } from "./config"
import { App } from "./components/App"
import { store } from "./store"

import { ErrorPage } from "./components/pages/ErrorPage"
import type { QuizId } from "./types"
import { Logout, LoginCallback, LOGIN_CALLBACK_URL, Login } from "./components/pages/LoginCallback"
import { buildQuestionUrl, buildQuizUrl, useQuizHistory } from "./hooks/useQuizHistory"
import { initUserId } from "./hooks/useInitialization/initUtils"
import { useAmplitude } from "./hooks/analytics/useAmplitude"
import { useGoogleAnalytics } from "./hooks/analytics/useGoogleAnalytics"
import { initSmpLink } from "./utils/smpLink"
import { commonFontFaces, ChakraProviderWithTheme, ThemeProvider } from "./theme"
import { downloadQuizThunk, setUserIdUpdated } from "./store/survey/actions"
import { useDispatch, useSelector } from "./hooks/redux"
import {
  selectFirstQuiz,
  selectIsQuizLoading,
  selectUserIdUpdated,
  useIsLoadingStatusError,
  useQuizData,
} from "./store/survey/selectors"
import { useNavigateWithSearchParams } from "@poraai/shared/useNavigateWithSearchParams"
import { initCountryInfo } from "./utils/countryCode"

import "./i18n"
import { usePinterest } from "./hooks/analytics/usePinterest"
import { useRunCompletionAction } from "./hooks/useNextQuiz"

const quizUrlPrefix = getConfig().constants.quizUrlPrefix

const SENTRY_DSN = getConfig().sentry.dsn

let createBrowserRouter = originalCreateBrowserRouter
if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: getConfig().environment,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
    ],
    tracesSampleRate: 1.0,
  })

  createBrowserRouter = Sentry.wrapCreateBrowserRouter(originalCreateBrowserRouter)
}

const RedirectToFirstQuiz: FC = () => {
  const {
    params: { quiz = "" },
  } = useQuizHistory()
  const navigate = useNavigateWithSearchParams()

  useEffect(() => {
    navigate(buildQuizUrl(getConfig().constants.startQuiz), undefined, { replace: true })
  }, [quiz])

  return <Outlet />
}

const RedirectQuizToFirstQuestion: FC = () => {
  const params = useParams()
  const navigate = useNavigateWithSearchParams()
  const runCompletionAction = useRunCompletionAction()
  console.info("RedirectQuizToQuestion", params)

  const { quizId } = useParams<{ quizId: QuizId }>()
  const quizData = useQuizData(quizId ?? "")
  useEffect(() => {
    if (quizData) {
      if (
        (typeof quizData?.questions_v2 === "undefined" || quizData?.questions_v2.length === 0) &&
        quizData?.on_completion_action
      ) {
        runCompletionAction()
        return
      }

      const questionId = quizData.questions_v2[0]?.parameters?.id // TODO: move to selector
      if (quizId && questionId) {
        navigate(buildQuestionUrl(quizId, questionId), undefined, { replace: true })
        return
      }

      throw new Error("Unknown first question")
    }
  }, [quizId, quizData])

  return null
}

const useLogPageView = () => {
  const { logPageView } = useAmplitude()
  const { pageView: logPageViewGA } = useGoogleAnalytics()
  const { pageView: logPageViewPin } = usePinterest()
  useEffect(() => {
    addEventListener("popstate", (event: PopStateEvent) => {
      const location = document.location
      const url = new URL(location.pathname, String(location))
      url.hash = location.hash
      url.search = location.search
      logPageView(String(url))
      logPageViewGA(String(url))
      logPageViewPin(String(url))
    })
  }, [logPageView, logPageViewGA, logPageViewPin])
}

const Root: FC = () => {
  useLogPageView()
  return <Outlet />
}

export const useDownloadData = () => {
  const { quizId, questionId } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigateWithSearchParams()
  const quizData = useQuizData(quizId!)
  const isQuizLoading = useSelector(selectIsQuizLoading)
  const isLoadingStatusError = useIsLoadingStatusError()
  const runCompletionAction = useRunCompletionAction()

  // Selectors
  const firstQuiz = useSelector(selectFirstQuiz)

  console.log("DownloadData", quizId, questionId)

  useEffect(() => {
    if (isQuizLoading || isLoadingStatusError) {
      return
    }

    if (
      (typeof quizData?.questions_v2 === "undefined" || quizData?.questions_v2.length === 0) &&
      quizData?.on_completion_action
    ) {
      runCompletionAction()
      return
    }

    const parts = (quizId ?? "").split("@@")
    if (parts[1] && parts[0]) {
      if (quizData?.questions_v2[0]?.parameters?.id === parts[1]) {
        navigate(buildQuestionUrl(parts[0], parts[1]), undefined, { replace: true })
      } else {
        /* HACK for case when API response doesn't include question from nextQuizId  */
        navigate(buildQuizUrl(parts[0]), undefined, { replace: true })
      }
      return
    }

    if (quizId === getConfig().constants.startQuiz && firstQuiz) {
      navigate(buildQuizUrl(firstQuiz.id), undefined, { replace: true })
      return
    }

    if (!quizData) {
      dispatch(downloadQuizThunk({ quizId, questionId }))
      return
    }
  }, [
    dispatch,
    quizId,
    questionId,
    navigate,
    isQuizLoading,
    quizData,
    firstQuiz,
    isLoadingStatusError,
  ])

  return
}

const DataLoader: FC = () => {
  useDownloadData()
  const { quizId } = useParams()
  const isLoadingStatusError = useIsLoadingStatusError()
  const parts = (quizId ?? "").split("@@")
  if (parts[1]) {
    return null // TODO loader here
  }
  if (isLoadingStatusError) {
    return <ErrorPage title="Loading Error" />
  }

  return <Outlet />
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <RedirectToFirstQuiz />,
      },
      {
        path: "error",
        element: <ErrorPage title="Error" />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "logout",
        element: <Logout />,
      },
      {
        path: LOGIN_CALLBACK_URL,
        element: <LoginCallback />,
      },
      {
        path: quizUrlPrefix,
        children: [
          {
            index: true,
            element: <RedirectToFirstQuiz />,
          },
          {
            path: ":quizId",
            element: <DataLoader />,
            children: [
              {
                index: true,
                element: <RedirectQuizToFirstQuestion />,
              },
              {
                path: ":questionId",
                element: <App />,
              },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <RedirectToFirstQuiz />,
      },
    ],
  },
])
const AppProviders: FC = () => (
  <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>}>
    <ThemeProvider theme={getConfig().theme}>
      <ChakraProviderWithTheme>
        <Global styles={commonFontFaces(getConfig().theme)} />
        <ReduxProvider store={store}>
          <RouterProvider router={router} />
        </ReduxProvider>
      </ChakraProviderWithTheme>
    </ThemeProvider>
  </Sentry.ErrorBoundary>
)

const main = (): Promise<unknown> => {
  if (import.meta.env["REACT_APP_MOCK"]) {
    return import("./mocks/browser").then(({ worker }) => {
      worker.start()
    })
  }
  initSmpLink()
  return Promise.resolve(undefined)
}

main()
  .then(() => Promise.all([initUserId(), apiGetConfig().then(setupConfig), initCountryInfo()]))
  .catch((error) => {
    Sentry.captureMessage("Initialization error")
    Sentry.captureException(error)
  })
  .finally(() => {
    const rootEl = document.getElementById("root")
    if (rootEl) {
      const root = createRoot(rootEl)
      root.render(<AppProviders />)
    }
    window.addEventListener(
      "CookiebotOnLoad",
      () => {
        if (window.Cookiebot?.consent?.marketing) {
          sendMarketingAttributes()
        }
      },
      false
    )
    if (window.Cookiebot && window.Cookiebot?.consent?.marketing) {
      sendMarketingAttributes()
    }
  })

if (typeof window._AutofillCallbackHandler === "undefined") {
  window._AutofillCallbackHandler = () => void 0
}
