import { FC, ReactNode } from "react"
import { TT, TTString } from "~/components/shared/AttributedString"
import { Box, BoxProps, Center, Flex, Portal } from "@chakra-ui/react"
import { LoviLogoIcon, PoraLogoIcon } from "./Icons"
import { useIsLoviTheme } from "~/theme/themeProvider"

export const WelcomeBlur: FC<{ image: string; color?: string; children: ReactNode }> = ({
  image,
  color,
  children,
}) => {
  const isLoviTheme = useIsLoviTheme()
  return (
    <>
      <BackgroundImage image={image} color={color} />

      <Flex
        h="full"
        pos="relative"
        pt={4}
        pb={6}
        px={6}
        zIndex={1}
        flexDirection="column"
        justifyContent="space-between"
      >
        <Center>{isLoviTheme ? <LoviLogoIcon /> : <PoraLogoIcon />}</Center>
        {children}
      </Flex>
    </>
  )
}

export const BlurBox: FC<Omit<BoxProps, "children"> & { children: TTString }> = ({
  children,
  ...props
}) => (
  <Box
    alignSelf="center"
    textStyle="Subtitle/Primary"
    textColor="Base/neutralPrimary"
    py={2}
    px={4}
    background="rgba(255, 255, 255, 0.1)"
    backdropFilter="blur(10px)"
    borderRadius="3xl"
    {...props}
  >
    <TT>{children}</TT>
  </Box>
)

export const BgImage: FC<BoxProps> = (props) => (
  <Box
    position="absolute"
    left={0}
    right={0}
    top={0}
    bottom={0}
    bgPosition="top center"
    bgSize="cover"
    {...props}
  />
)

export const BgGradient: FC<BoxProps> = ({ color = "#000", ...props }) => (
  <Box
    position="absolute"
    left={0}
    right={0}
    top={0}
    bottom={0}
    bg={{
      base: `linear-gradient(180deg, rgba(0, 0, 0, 0) 40.5%, ${color} 100%)`,
      lg: `linear-gradient(180deg, rgba(0, 0, 0, 0) 31.8%, ${color} 95%)`,
    }}
    {...props}
  />
)

const BackgroundImage: FC<{ image: string; color?: string }> = ({ image, color = "#000" }) => (
  <Portal>
    <BgImage bgImage={image} position="fixed" />
    <BgGradient
      color={color}
      position="fixed"
      top={{ base: "-100px", lg: 0 }}
      bottom={{ base: "-20px", lg: "-46px" }}
    />
  </Portal>
)
