import { createIcon } from "@chakra-ui/react"

export const ChevronIcon = createIcon({
  viewBox: "0 0 16 16",
  path: (
    <path
      d="M7 3L11.6429 7.28571L7.27311 13"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  ),
})

export const AppleIcon = createIcon({
  viewBox: "0 0 11 14",
  path: (
    <path
      d="M7.45312 2.90625C7.04688 3.39844 6.35938 3.76562 5.8125 3.76562C5.75 3.76562 5.6875 3.75781 5.64844 3.75C5.64062 3.71875 5.625 3.625 5.625 3.53125C5.625 2.90625 5.94531 2.28125 6.28906 1.89062C6.72656 1.375 7.46094 0.992188 8.07031 0.96875C8.08594 1.03906 8.09375 1.125 8.09375 1.21094C8.09375 1.83594 7.82812 2.45312 7.45312 2.90625ZM7.88281 3.89844C8.22656 3.89844 9.46875 3.92969 10.2812 5.10156C10.2109 5.15625 8.97656 5.85156 8.97656 7.40625C8.97656 9.20312 10.5469 9.84375 10.5938 9.85938C10.5859 9.89844 10.3438 10.7266 9.76562 11.5781C9.25 12.3203 8.70312 13.0703 7.88281 13.0703C7.05469 13.0703 6.84375 12.5859 5.89844 12.5859C4.96875 12.5859 4.64062 13.0859 3.89062 13.0859C3.13281 13.0859 2.60938 12.3906 2.00781 11.5469C1.30469 10.5469 0.742188 9 0.742188 7.53125C0.742188 5.17969 2.27344 3.92969 3.78125 3.92969C4.57812 3.92969 5.24219 4.45312 5.75 4.45312C6.22656 4.45312 6.96875 3.89844 7.88281 3.89844Z"
      fill="currentColor"
    />
  ),
})
/*
<svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path

  fill="#292824"
/>
</svg>
 */
