import { FC, useMemo } from "react"
import { Heading, SimpleGrid } from "@chakra-ui/react"

import { VFlex } from "~/components/shared/VFlex"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import {
  NextButton,
  NextButtonDefaultContainer as NextButtonContainer,
} from "~/components/shared/NextButton"
import { WellnessProfileScreen_Level } from "~/generated/interview_service"

import {
  FakeNumbers,
  mapEnumToLevel,
  HeaderExtended,
  SliderWithTooltip,
  GoalsList2,
  AnimationWrapper,
  AnimationWrapper2,
} from "./components"
import { FaceScanResultRenderContainer } from "../FaceScannerPage/FaceScanResultRenderContainer"

export const WellnessProfileWithFaceScanResultPage: FC<{
  level: WellnessProfileScreen_Level
  properties: Record<string, string>
  next: () => void
}> = ({ level: levelEnum, properties = {}, next }) => {
  const level = useMemo(() => mapEnumToLevel(levelEnum), [levelEnum])

  return (
    <BasePageWrapper gap={2} paddingBottom={0} paddingX={0}>
      <VFlex
        gap={5}
        alignSelf="stretch"
        layerStyle="attentionFrameWhite"
        marginTop="-64px"
        paddingTop="64px"
        paddingBottom={8}
        paddingX={6}
        borderTopRadius={0}
      >
        <HeaderExtended level={level} />
        <SliderWithTooltip level={level} />
        <SimpleGrid columns={2} gap={4}>
          <GoalsList2 data={properties} />
          <AnimationWrapper>
            <FaceScanResultRenderContainer
              sx={{ aspectRatio: "0.8" }}
              height={{ base: "170px", md: "208px", lg: "260px" }}
              borderRadius="brand20"
            />
          </AnimationWrapper>
        </SimpleGrid>
      </VFlex>
      <AnimationWrapper2>
        <VFlex padding={6} gap={6} layerStyle="attentionFrameWhite">
          <Heading size="Header/Secondary">
            Get your Dream Skin with our data-driven approach!
          </Heading>
          <FakeNumbers />
        </VFlex>
      </AnimationWrapper2>
      <AnimationWrapper2>
        <NextButtonContainer>
          <NextButton onClick={next} />
        </NextButtonContainer>
      </AnimationWrapper2>
    </BasePageWrapper>
  )
}
