import { Children, FC, ReactNode } from "react"
import { cn } from "@bem-react/classname"

import "./Page.scss"

const cnPage = cn("Page")

const HeroImage: FC<{ children: ReactNode }> = ({ children }) => <div>{children}</div>

const Bottom: FC<{ children: ReactNode }> = ({ children }) => (
  <div className={cnPage("Bottom")}>{children}</div>
)

const Title: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className={cnPage("Title")}>{children}</div>
}
const Description: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className={cnPage("Description")}>{children}</div>
}
const Content: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className={cnPage("Content")}>{children}</div>
}

const useSlots = (children: ReactNode, slots: FC<any>[]) => {
  const slotsElems: ReactNode[] = []
  const rest: ReactNode[] = []

  slots.forEach((slot) => {
    let el = null
    Children.forEach(children, (child) => {
      // @ts-expect-error fixme
      if (child && child.type === slot) {
        el = child
      }
    })

    slotsElems.push(el)
  })

  Children.forEach(children, (child) => {
    // @ts-expect-error fixme
    if (child && child.type && !slots.includes(child.type)) {
      slotsElems.push(child)
    }
  })

  return [...slotsElems, ...rest]
}

type PageType = {
  Bottom: typeof Bottom
  Title: typeof Title
  Description: typeof Description
  HeroImage: typeof HeroImage
  Content: typeof Content
  (props: { children: ReactNode; className: string }): JSX.Element
}
// fixme
// eslint-disable-next-line react/prop-types
const Page: PageType = ({ children, className }) => {
  const [heroImage, bottom, title, description, ...rest] = useSlots(children, [
    HeroImage,
    Bottom,
    Title,
    Description,
  ])

  return (
    <div className={cnPage(null, [className])}>
      {heroImage}
      <main className={cnPage("Main")}>
        {title}
        {description}
        {rest}
      </main>
      <footer>{bottom}</footer>
    </div>
  )
}

Page.Bottom = Bottom
Page.Title = Title
Page.Description = Description
Page.HeroImage = HeroImage
Page.Content = Content

export { Page }
