import { FC, useMemo } from "react"
import { Box, Flex, Heading } from "@chakra-ui/react"

import { NextPageCb } from "~/hooks/useNextQuiz"
import type { TTString } from "~/components/shared/AttributedString"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"
import {
  NextButton,
  NextButtonBottomSlideUpContainer as NextButtonContainer,
} from "~/components/shared/NextButton"
import { RiveOrImage } from "~/components/shared/RiveImage"
import { getResultsDate } from "~/components/shared/getResultDate"

const LONG_WEEKS = 6
const SHORT_WEEKS = 5

export const ProgressWeeksPage: FC<{
  title: TTString
  mainIssue: string
  image: string
  next: NextPageCb
  variant: string
}> = ({ image, title, mainIssue, next: onNext, variant }) => {
  const isLongVariant = variant == "long"
  let weeks = SHORT_WEEKS
  if (isLongVariant) {
    weeks = LONG_WEEKS
  }

  const todayDateTitle = useMemo(() => getResultsDate(0), [])
  const finishDateTitle = useMemo(() => getResultsDate(weeks), [weeks])

  return (
    <BasePageWrapper>
      <BasePageHeader textAlign="center" title={title} />
      <Box textAlign="center" mt={3} textStyle="Paragraph/Primary">
        The only skin program you’ll ever need
      </Box>
      <Box
        boxShadow="0px 8px 28px 0px rgba(41, 40, 36, 0.04), 0px 1px 2px 0px rgba(41, 40, 36, 0.06);"
        borderRadius="24px"
        bgColor="white"
        mt={8}
        p={4}
        textAlign="center"
      >
        <Box textStyle="Paragraph/Primary" textColor="Base/baseSecondary">
          Based on your answers you can get
        </Box>
        <Heading as="h2" size="Header/Secondary" mt={1} textColor="Base/accentPrimaryFocused">
          {mainIssue} by {finishDateTitle}
        </Heading>
      </Box>
      <Box mt={5}>
        <RiveOrImage width="full" src={image} ratio={1778 / 1104} />
        {isLongVariant && (
          <Flex px={2} textStyle="Subtitle/Secondary" justifyContent="space-between">
            <Box>{todayDateTitle}</Box>
            <Box>{finishDateTitle}</Box>
          </Flex>
        )}
      </Box>
      <NextButtonContainer visible>
        <NextButton onClick={onNext} />
      </NextButtonContainer>
    </BasePageWrapper>
  )
}
