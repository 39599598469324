import { FC, useEffect } from "react"
import { NextPageCb } from "~/hooks/useNextQuiz"
import { Box, Flex, Grid, Image, Text } from "@chakra-ui/react"
import {
  NextButton,
  NextButtonBottomSlideUpContainer as NextButtonContainer,
} from "~/components/shared/NextButton"
import { TTString } from "~/components/shared/AttributedString"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"
import {
  CheckCircleIcon,
  CheckIcon,
  CrossCircleIcon,
  CrossIcon,
} from "~/components/pages/ProductFitResultPage/Icons"
import { ProductFitResultScreen } from "~/generated/interview_service"
import { useAmplitude } from "~/hooks/analytics/useAmplitude"
import { useCurrentTheme } from "~/theme/themeProvider"

export const ProductFitResultPage: FC<{
  title: TTString
  next: NextPageCb
  fit_products: ProductFitResultScreen["fit_products"]
  not_fit_products: ProductFitResultScreen["not_fit_products"]
}> = ({ title, next: onNext, fit_products, not_fit_products }) => {
  const { logProductFitResult } = useAmplitude()

  useEffect(() => {
    logProductFitResult(
      fit_products?.products_list.map((p) => p.description) || [],
      not_fit_products?.products_list.map((p) => p.description) || []
    )
  }, [fit_products, logProductFitResult, not_fit_products])

  return (
    <BasePageWrapper h="full" bgColor="white">
      <BasePageHeader mb={6} title={title} />
      <Grid pb="40px" gridColumnGap={1} gridTemplateColumns="repeat(2, 1fr)">
        {fit_products?.products_list && (
          <Box>
            <Flex mb={2} alignItems="center">
              <CheckCircleIcon mr={1} />
              <Box>
                <Box textStyle="Header/Tertiary" textColor="Other/GreenDark">
                  Great fit
                </Box>
                <Box textStyle="Subtitle/Tertiary" textColor="Base/baseDisabled">
                  {fit_products.total_products} products
                </Box>
              </Box>
            </Flex>
            <Grid gridAutoRows="1fr" gridRowGap={1}>
              {fit_products.products_list.map((p, i) => (
                <ProductCard key={i} {...p} theme={ProductCardTheme.GOOD} />
              ))}
            </Grid>
          </Box>
        )}
        {not_fit_products?.products_list && (
          <Box>
            <Flex mb={2} alignItems="center">
              <CrossCircleIcon mr={1} />
              <Box>
                <Box textStyle="Header/Tertiary" textColor="Other/Error">
                  Doesn&apos;t fit
                </Box>
                <Box textStyle="Subtitle/Tertiary" textColor="Base/baseDisabled">
                  {not_fit_products.total_products} products
                </Box>
              </Box>
            </Flex>
            <Grid gridAutoRows="1fr" gridRowGap={1}>
              {not_fit_products.products_list.map((p, i) => (
                <ProductCard key={i} {...p} theme={ProductCardTheme.BAD} />
              ))}
            </Grid>
          </Box>
        )}
      </Grid>
      <NextButtonContainer visible>
        <NextButton onClick={onNext} />
      </NextButtonContainer>
    </BasePageWrapper>
  )
}

enum ProductCardTheme {
  BAD = "bad",
  GOOD = "good",
}

const poraConfig = {
  [ProductCardTheme.GOOD]: {
    bgColor: "Base/neutralSecondary",
    activeColor: "Other/GreenDark",
    boxShadow: undefined,
    icon: <CheckIcon />,
  },
  [ProductCardTheme.BAD]: {
    bgColor: "rgba(248, 64, 24, 0.1)",
    activeColor: "Other/Error",
    boxShadow: undefined,
    icon: <CrossIcon />,
  },
}

const getProductCardThemeConfig = (theme: Theme) => {
  if (theme === "lovi") {
    return {
      ...poraConfig,
    }
  }

  return poraConfig
}

function ProductCard({
  image,
  fit_percentage,
  description,
  theme,
}: {
  image: string
  fit_percentage: number
  description: string
  theme: ProductCardTheme
}) {
  const currentGlobalTheme = useCurrentTheme()
  const config = getProductCardThemeConfig(currentGlobalTheme)[theme]
  return (
    <Box borderRadius="24px" boxShadow={config.boxShadow} bgColor={config.bgColor} py={3} px={4}>
      <Image
        borderRadius="12px"
        mb={3}
        objectFit="cover"
        objectPosition="top"
        bgColor="white"
        sx={{ aspectRatio: "1/1" }}
        width="56px"
        src={image}
      />
      <Flex mb={1} alignItems="center">
        {config.icon}
        <Box ml={0.5} textStyle="Subtitle/Tertiary" textColor={config.activeColor}>
          {fit_percentage}% fit to you
        </Box>
      </Flex>
      <Text noOfLines={2} textStyle="Subtitle/Tertiary" textColor="Base/basePrimary">
        {description}
      </Text>
    </Box>
  )
}
