import { FC, useEffect } from "react"
import { Box, Heading } from "@chakra-ui/react"
import { keyframes } from "@emotion/react"
import { URLString } from "~/types"
import { IfTTExists, TT, TTString } from "~/components/shared/AttributedString"

const loadingKeyframes = keyframes`
  0% {
    background-position-y: 100%;
  }
  100% {
    background-position-y: 0;
  }
`
const loadingAnimation = `${loadingKeyframes} 1s ease-in-out alternate infinite`
const h = "100vh"
const w = "full"

export const FaceScanLoading: FC<{ scan: URLString; loadingTitle?: TTString }> = ({
  scan,
  loadingTitle,
}) => {
  useEffect(() => {
    /* FIXME */
    const el = document.getElementById("navigation_progressbar")
    if (el) {
      el.hidden = true
    }

    return () => {
      if (el) {
        el.hidden = false
      }
    }
  }, [])

  return (
    <>
      <Box
        position="absolute"
        h={h}
        w={w}
        backgroundImage={scan}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        backgroundPosition="center"
        sx={{
          "#navigation_progressbar": {
            display: "none",
          },
        }}
      />
      <Box
        position="absolute"
        h={h}
        w={w}
        sx={{
          background: `100% 0 / 100% 300px no-repeat  linear-gradient(180deg,
        rgb(0 0 0 / 1) 0%,
        rgb(0 0 0 / 0) 100%
        )`,
        }}
      />
      <Box
        position="absolute"
        h={h}
        w={w}
        backgroundRepeat="no-repeat"
        animation={loadingAnimation}
        sx={{
          background: `0 50% / 100% 200% linear-gradient(180deg,
        rgb(255 255 255 / 0) 0%,
        rgb(255 255 255 / 0.3) 49.9%,
        rgb(255 255 255 / 1) 50%,
        rgb(255 255 255 / 1) 50.1%,
        rgb(255 255 255 / 0.3) 50.2%,
        rgb(255 255 255 / 0) 100%
        )`,
        }}
      />
      <Heading
        position="absolute"
        w="full"
        paddingX={6}
        top={104}
        textStyle="Lead/Secondary"
        color="Base/neutralPrimary"
      >
        <IfTTExists if={loadingTitle} other="Analyzing your picture...">
          <TT>{loadingTitle}</TT>
        </IfTTExists>
      </Heading>
    </>
  )
}
