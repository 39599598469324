import { createAction } from "@reduxjs/toolkit"
import { apiGetQuiz, apiSaveAnswers, type QuizType } from "~/api"
import type { QuestionId, QuizId } from "~/types"
import { ActionData, InterviewServiceSaveAnswersResponse } from "~/generated/interview_service"
import { getUserId, NavigationDataType } from "~/hooks/useInitialization/initUtils"
import { logError } from "~/utils/logError"
import { ApiError, CallValidationError } from "~/errors"
import type { AppDispatch, RootState } from "~/store"
import {
  isLastQuestionInQuiz as isLastQuestionInQuizSelector,
  nextQuestion as nextQuestionSelector,
  currentAnswers as currentAnswersSelector,
  currentAnswersV2 as currentAnswersV2Selector,
} from "./selectors"
import { STATUS } from "./reducer"
import { redirect } from "react-router-dom"

export const addQuiz = createAction<QuizType>("add_quiz")

export const saveAnswer = createAction<{
  quizId: QuizId
  answers: Record<QuestionId, string[]>
  answersV2?: Record<QuestionId, ActionData[]>
}>("save_answer")
export const releaseAnswers = createAction("release_answers")

export const setStatus = createAction<{ status: STATUS }>("set_status")
export const setUserIdUpdated = createAction("set_user_id_updated")

export const downloadQuizThunk = ({ quizId, questionId }: NavigationDataType) => {
  return async (dispatch: AppDispatch /* , getState: () => RootState */) => {
    dispatch(setStatus({ status: "LOADING" }))
    const userId = getUserId()
    if (!userId) {
      throw new CallValidationError("user_id is undefined")
    }
    if (!quizId) {
      throw new CallValidationError("quiz_id is undefined")
    }

    try {
      const response = await apiGetQuiz(userId, quizId, questionId ?? "")
      if (!response.quiz) {
        throw new ApiError("Empty quiz response")
      }

      dispatch(addQuiz(response.quiz))
      if (quizId !== response.quiz.id) {
        logError(`Response quiz id mismatch ${quizId} != ${response.quiz.id}`)
      }
      if (questionId) {
        const responseQuestionsIds = response.quiz.questions_v2.map((q) => q.parameters?.id)
        if (!responseQuestionsIds.find((id) => id === questionId)) {
          const _responseQuestionsIds = responseQuestionsIds.join(",")
          logError(
            `Can't find question in response questionId = ${questionId} responseQuestionsIds=[${_responseQuestionsIds}]`
          )
        }
      }
    } catch (error) {
      dispatch(setStatus({ status: "ERROR" }))
      throw error
    }

    dispatch(setStatus({ status: "IDLE" }))
  }
}

export const uploadAnswerThunk = ({ quizId, questionId }: NavigationDataType) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState()
    const userId = getUserId()
    const isLastQuestionInQuiz = isLastQuestionInQuizSelector(quizId, questionId)(state)
    const nextQuestionData = nextQuestionSelector(quizId, questionId)(state)

    if (
      userId &&
      isLastQuestionInQuiz &&
      ("quizId" in nextQuestionData || nextQuestionData.status === "survey_accomplished")
    ) {
      const nextQuizId = "quizId" in nextQuestionData ? nextQuestionData.quizId : undefined
      dispatch(setStatus({ status: "LOADING" }))
      let response: InterviewServiceSaveAnswersResponse | undefined
      try {
        const answers = currentAnswersSelector(quizId)(state)
        const answersV2 = currentAnswersV2Selector(quizId)(state)
        response = await apiSaveAnswers({
          userId,
          quizId: quizId!, // FIXME
          answers,
          answersV2,
          nextQuizId,
        })
      } finally {
        dispatch(setStatus({ status: "IDLE" }))
      }
      dispatch(releaseAnswers())
      if (response.quiz) {
        dispatch(addQuiz(response.quiz))

        const [_nextQuizId, _nextQuestionId] = nextQuizId?.split("@@") || []
        if (_nextQuizId !== response.quiz.id) {
          logError(`Response quiz id mismatch ${_nextQuizId} != ${response.quiz.id}`)
        }
        if (_nextQuestionId) {
          const responseQuestionsIds = response.quiz.questions_v2.map((q) => q.parameters?.id)
          if (!responseQuestionsIds.find((id) => id === _nextQuestionId)) {
            const _responseQuestionsIds = responseQuestionsIds.join(",")
            logError(
              `Can't find question in response questionId = ${_nextQuestionId} responseQuestionsIds=[${_responseQuestionsIds}]`
            )
          }
        }
      }
    }

    return undefined
  }
}
