import { FC, useMemo } from "react"
import { NextPageCb } from "~/hooks/useNextQuiz"
import { Box, Center, chakra, Flex, Grid, Heading, Image } from "@chakra-ui/react"
import {
  NextButton,
  NextButtonBottomSlideUpContainer as NextButtonContainer,
} from "~/components/shared/NextButton"
import { TT, TTString } from "~/components/shared/AttributedString"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import { FramerBox } from "~/components/shared/FramerBox"
import { ArrowIcon } from "~/components/pages/ProductFitPage/Icons"
import { useTransform } from "framer-motion"
import { useRangeMotionValue } from "~/hooks/useRangeMotionValue"
import { MotionValuePrinter } from "~/components/shared/MotionValuePrinter/MotionValuePrinter"
import { ProductFitScreen_ViewVariant } from "~/generated/interview_service"
import { SCREEN_CONFIG_VARIANTS } from "./content"

const whiteBoxAppearDelay = 0.05
const whiteBoxAppearAnimation = {
  animate: {
    opacity: [0, 1],
    translateY: [24, 0],
  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore no problem in operation, although type error appears.
  transition: {
    duration: 0.3,
    delay: whiteBoxAppearDelay,
    ease: "easeInOut",
  },
}

export const ProductFitPage: FC<{
  title: TTString
  productImages: string[]
  variant: ProductFitScreen_ViewVariant
  next: NextPageCb
}> = ({ title, next: onNext, productImages, variant = ProductFitScreen_ViewVariant.top }) => {
  const config =
    SCREEN_CONFIG_VARIANTS[variant] || SCREEN_CONFIG_VARIANTS[ProductFitScreen_ViewVariant.top]
  const {
    activeColor,
    bgColor,
    title1,
    title2,
    infiniteLine,
    buttonUpTitle,
    moreProductsTitle,
    totalProductsCount: finalTotalProductsCount,
    prevTotalProductsCount,
    fitPercentage: finalFitPercentage,
    prevFitPercentage,
    bgImage,
  } = config
  const productsAnimationDuration = 0.9
  const productsAnimationDelay = whiteBoxAppearDelay + 0.7
  const { motionValue: totalProductsMotion } = useRangeMotionValue(
    prevTotalProductsCount || finalTotalProductsCount,
    finalTotalProductsCount,
    {
      duration: productsAnimationDuration,
      delay: productsAnimationDelay,
    }
  )
  const normTotalProductsMotion = useTransform(totalProductsMotion, (v) => Math.floor(v))

  const { motionValue: fitPercentageMotion } = useRangeMotionValue(
    prevFitPercentage || finalFitPercentage,
    finalFitPercentage,
    {
      duration: 1.2,
      delay: productsAnimationDuration + productsAnimationDelay + 0.1,
    }
  )
  const normFitPercentageMotion = useTransform(fitPercentageMotion, (v) => Math.floor(v))

  return (
    <>
      <BasePageWrapper minHeight="100%" justifyContent="space-between" pos="relative">
        <Background bgColor={bgColor} bgImage={bgImage} />
        <Box>
          <Box
            mb={6}
            textAlign="center"
            textStyle="Subtitle/Secondary"
            textColor="Base/neutralPrimary"
          >
            <TT>{title}</TT>
          </Box>
          <Flex mb={8} gap={0.5} flexDirection={"column"}>
            {infiniteLine ? (
              <ProductImagesInfiniteLine images={productImages} />
            ) : (
              <ProductImagesGrid moreProductsTitle={moreProductsTitle} images={productImages} />
            )}
            {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
            {/* @ts-ignore no problem in operation, although type error appears.*/}
            <FramerBox
              {...whiteBoxAppearAnimation}
              py={8}
              px={10}
              borderRadius="32px"
              bgColor="Base/neutralPrimary"
            >
              <Heading textAlign="center" as="h2" size="Header/Primary">
                <Box>
                  <MotionValuePrinter motionValue={normTotalProductsMotion} />
                  <TT>{title1}</TT>
                </Box>
                <Box textStyle="Subtitle/Secondary" fontFamily="body">
                  with
                </Box>
                <Box>
                  <chakra.span textColor={activeColor}>
                    <MotionValuePrinter motionValue={normFitPercentageMotion} />%
                  </chakra.span>
                  <TT>{title2}</TT>
                </Box>
              </Heading>
            </FramerBox>
          </Flex>
        </Box>
        <Box>
          <Box textAlign="center" textStyle="Subtitle/Secondary" textColor="Base/neutralPrimary">
            {buttonUpTitle || (
              <>
                <ArrowIcon mr={0.5} />
                Let’s increase the fit
              </>
            )}
          </Box>
        </Box>
      </BasePageWrapper>
      <NextButtonContainer showGradient={false} visible>
        <NextButton variant="nextWhite" onClick={onNext} />
      </NextButtonContainer>
    </>
  )
}

const PRODUCT_IMAGE_WIDTH = 40
const LINE_ANIMATION_DURATION_SEC = 20

function ProductImagesInfiniteLine({ images = [] }: { images: string[] }) {
  // this is needed to imitate infinite carousel
  // when animation shift 5 products and animation finished, duplicated 5 stays in place of start
  // |.....| - it means 5 products are displayed on screen
  // |.....|..... - this is animation start point. 5 products on screen, 5 hidden on the right side
  // .....|.....| - this is animation end point. 5 products on screen, initial products are hidden on the left side
  // then we start animation from zero
  // |.....|..... and user can't see that we started animation from zero,
  // because in the end point duplicated images looks exactly like start of animation
  const doubledImages = useMemo(() => [...images, ...images], [images])
  return (
    <>
      <Box height={`${PRODUCT_IMAGE_WIDTH + 24}px`}></Box>
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
      {/* @ts-ignore no problem in operation, although type error appears.*/}
      <FramerBox
        {...whiteBoxAppearAnimation}
        display="flex"
        overflow="hidden"
        py={6}
        borderRadius="32px"
        bgColor="Base/neutralPrimary"
      >
        <FramerBox
          // preserve block height, while images are loading
          height={`${PRODUCT_IMAGE_WIDTH}px`}
          display="flex"
          animate={{
            translateX: [0, -(PRODUCT_IMAGE_WIDTH * images.length)],
          }}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore no problem in operation, although type error appears.
          transition={{
            duration: LINE_ANIMATION_DURATION_SEC,
            delay: 0.1,
            ease: "linear",
            repeat: Infinity,
          }}
        >
          {doubledImages.map((img, i) => {
            return (
              <Image
                style={{ aspectRatio: "1/1" }}
                w={`${PRODUCT_IMAGE_WIDTH}px`}
                key={i}
                src={img}
              />
            )
          })}
        </FramerBox>
      </FramerBox>
    </>
  )
}

function ProductImagesGrid({
  images = [],
  moreProductsTitle,
}: {
  images: string[]
  moreProductsTitle?: string
}) {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore no problem in operation, although type error appears.
    <FramerBox
      {...whiteBoxAppearAnimation}
      py={6}
      px={5}
      borderRadius="32px"
      bgColor="Base/neutralPrimary"
    >
      <Grid gridRowGap={1.5} gridTemplateColumns="repeat(7, 1fr)" gridTemplateRows="repeat(2, 1fr)">
        {images.map((img, i) => (
          // aspectRatio is necessary to preserve block height, while images are loading
          <Center style={{ aspectRatio: "1/1" }} key={i}>
            <Image h="full" src={img} />
          </Center>
        ))}
        {moreProductsTitle && (
          <Center
            borderRadius="12px"
            bgColor="Base/neutralSecondary"
            textStyle="Subtitle/Tertiary"
            textColor="Base/baseSecondary"
          >
            {moreProductsTitle}
          </Center>
        )}
      </Grid>
    </FramerBox>
  )
}

function Background({ bgColor, bgImage }: { bgColor: string; bgImage: string }) {
  return (
    <>
      <Box
        overflow="hidden"
        bgColor={bgColor}
        pos="absolute"
        top={0}
        left={0}
        right={0}
        zIndex={-2}
        bottom={0}
      />
      <FramerBox
        overflow="hidden"
        pos="absolute"
        top={0}
        left={0}
        zIndex={-1}
        right={0}
        bottom={0}
        bgImage={bgImage}
        bgSize="cover"
        bgPosition="center"
        animate={{
          opacity: [0, 1],
        }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore no problem in operation, although type error appears.
        transition={{
          duration: 0.3,
          ease: "easeInOut",
        }}
      ></FramerBox>
    </>
  )
}
