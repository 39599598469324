import { FC } from "react"
import { Box, Flex, FlexProps } from "@chakra-ui/react"

export const AllVariantButton: FC<
  FlexProps & { title: string; iconColor?: string; iconBgColor?: string }
> = ({
  title,
  iconColor = "currentColor",
  iconBgColor = "color-mix(in srgb, currentColor 50%, transparent)",
  ...props
}) => (
  <Flex alignItems="center" justifyContent="center" cursor="pointer" {...props}>
    <Box
      borderRadius="full"
      w="24px"
      h="24px"
      border="2px"
      borderColor={iconColor}
      bgColor={iconBgColor}
      marginRight={2}
    />
    <Box>{title}</Box>
  </Flex>
)
