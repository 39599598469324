import { FC } from "react"
import { NextPageCb } from "~/hooks/useNextQuiz"
import { Box, Flex, Spinner, Image } from "@chakra-ui/react"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import { useProgress } from "~/hooks/useProgress"
import { useIsLoviTheme } from "~/theme/themeProvider"

const duration = 4
const delay = 0.3

const ProgressBar: FC<{
  onComplete: () => void
  duration: number
  delay: number
}> = ({ onComplete, duration, delay }) => {
  const { progress100 } = useProgress({
    delay,
    duration,
    onComplete,
  })
  const isLoviTheme = useIsLoviTheme()
  const visualPercentage = Math.floor(progress100)
  const changeTitleColor = progress100 > 48

  return (
    <>
      <Flex
        bgColor="Base/neutralPrimary"
        h="40px"
        borderRadius="2xl"
        pos="relative"
        alignItems="center"
        justifyContent="center"
      >
        <Flex
          top={1}
          left={1}
          right={1}
          bottom={1}
          width={`calc(${progress100}% - 8px)`}
          pos="absolute"
          backgroundColor={isLoviTheme ? "Base/accentSecondary" : "Base/accentPrimary"}
          borderRadius="xl"
        />
        <Flex
          zIndex={1}
          textStyle="Subtitle/Primary"
          transition="color 200ms"
          textColor={changeTitleColor ? "Base/neutralPrimary" : "Base/basePrimary"}
        >
          {visualPercentage} %
        </Flex>
      </Flex>
    </>
  )
}

export const ProgressGraphPage: FC<{
  image: string
  next: NextPageCb
}> = ({ image, next: onNext }) => {
  return (
    <BasePageWrapper>
      <Flex
        mt="98px"
        mb={2.5}
        alignItems="center"
        justifyContent="center"
        textStyle="Subtitle/Tertiary"
      >
        Preliminary progress <Spinner size="sm" ml={1.5} />
      </Flex>
      <Box mx={3}>
        <ProgressBar onComplete={onNext} duration={duration} delay={delay} />
      </Box>
      <Box pos="absolute" bottom={0} left={0} right={0}>
        <Image w="full" src={image} />
      </Box>
    </BasePageWrapper>
  )
}
