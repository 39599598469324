import { FC, ReactNode, useState, useEffect } from "react"
import { Box, Heading, Flex, Text, SlideFade, Icon } from "@chakra-ui/react"

import { useIsPoraTheme } from "~/theme/themeProvider"
import { NextButton } from "~/components/shared/NextButton"
import { AttributedString, AttributedStringType } from "~/components/shared/AttributedString"

import PoraAvatar from "./PoraAvatar.svg?react"
import BubbleTail from "./BubbleTail.svg"

const QuoteBubble: FC<{ title: string; message: AttributedStringType; author?: string }> = ({
  title,
  message,
  author,
}) => {
  const isPoraTheme = useIsPoraTheme()
  return (
    <Flex direction="column" margin={6}>
      <Heading color="white" size="quoteScreen2" noOfLines={1}>
        {isPoraTheme && (
          <Icon as={PoraAvatar} fontSize="28px" marginRight={2} verticalAlign="top" />
        )}
        {title}
      </Heading>
      <Box
        _before={{
          content: '" "',
          display: "block",
          ml: "-15px",
          mt: "-23px",
          position: "absolute",
          width: "27px",
          height: "19px",
          bgImage: `url(${BubbleTail})`,
        }}
        borderRadius="3xl"
        padding={4}
        marginTop={4}
        backgroundColor="white"
      >
        <Text textStyle="Subtitle/Primary">
          <AttributedString>{message}</AttributedString>
        </Text>
        <Text
          noOfLines={2}
          marginTop={3}
          textStyle="Paragraph/Secondary"
          color="Base/baseSecondary"
        >
          {author}
        </Text>
      </Box>
    </Flex>
  )
}

/* FIXME QuestionPageMultiSelect duplicate */
const NextButtonContainer: FC<{ children: ReactNode }> = ({ children }) => (
  <Box paddingX={6} paddingY={4}>
    {children}
  </Box>
)

export const QuotePage: FC<{
  title: string
  message: AttributedStringType
  author: string | undefined
  background: string
  next: () => void
}> = ({ title, message, author, background, next }) => {
  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    setIsOpen(true)
  }, [setIsOpen])

  return (
    <Box
      width="100%"
      height="100%"
      backgroundImage={`
          linear-gradient(0, black 0, rgba(0, 0, 0, 0.1) 55%, rgba(0, 0, 0, 0) 80%), url('${background}')`}
      backgroundPosition="center top"
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
    >
      <Flex direction="column" justifyContent="flex-end" height="100%">
        <SlideFade
          in={isOpen}
          offsetY="25%"
          delay={0.25}
          transition={{ enter: { ease: "easeInOut", duration: 0.6 } }}
        >
          <QuoteBubble title={title} message={message} author={author} />
        </SlideFade>
        <NextButtonContainer>
          <NextButton onClick={next} />
        </NextButtonContainer>
      </Flex>
    </Box>
  )
}
