import { FC, ReactNode } from "react"
import { Box, Center, Flex } from "@chakra-ui/react"

import type { NextPageCb } from "~/hooks/useNextQuiz"
import { MessagesAnimation } from "~/components/shared/MessagesAnimation"
import { rotationAnimation } from "~/components/shared/rotationAnimation"

import circleImg from "./CircleLoader.png"

const LoaderCircle: FC = () => (
  <Box
    display="inline-block"
    width="56px"
    height="56px"
    backgroundImage={circleImg}
    backgroundSize="contain"
    backgroundRepeat="no-repeat"
    backgroundPosition="center"
    animation={rotationAnimation({ duration: 2 })}
  />
)
const LoaderSubtitle: FC<{ children: ReactNode }> = ({ children }) => (
  <Box marginY={4} color="base.baseSecondary">
    {children}
  </Box>
)

export const MessagesLoaderPage: FC<{
  messages: string[]
  next: NextPageCb
  delay?: number
  subtitle: string
}> = ({ messages, delay = 2, subtitle, next }) => (
  <Flex w="full" direction="column" justifyContent="center" alignItems="center" marginTop="112px">
    <LoaderCircle />
    <LoaderSubtitle>{subtitle}</LoaderSubtitle>
    <Center width="full" padding={5}>
      <MessagesAnimation messages={messages} delay={delay} onComplete={next} />
    </Center>
  </Flex>
)
