import { FC, useMemo, ReactNode, useState, useEffect } from "react"
import { animate, MotionValue, useMotionValue, useMotionValueEvent } from "framer-motion"
import { Box, Flex, FlexProps } from "@chakra-ui/react"

import type { AttributedStringType } from "~/components/shared/AttributedString"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import { BasePageHeader } from "~/components/shared/BasePageHeader/BasePageHeader"
import type { NextPageCb } from "~/hooks/useNextQuiz"
import { noop } from "~/utils"
import { ReviewsList } from "./ReviewItem"
import { reviews } from "./data"
import { ProgressBarItemWithIcon } from "./ProgressBarItemWithIcon"
import { QuestionModal } from "./QuestionModal"

const STOP1 = 0.67
const STOP2 = 0.33
const DEFAULT_DURATION1 = 1
const DEFAULT_DURATION2 = 1
const DEFAULT_DURATION3 = 1

type ProgressBarData = { title: string; seconds_to_complete: number }

const ProgressBarItem: FC<{ title: string; progress: MotionValue<number> }> = ({
  title,
  progress: progressMV,
}) => {
  const [progress, setProgress] = useState(0)
  useMotionValueEvent(progressMV, "change", setProgress)
  return <ProgressBarItemWithIcon title={title} progress={progress} />
}

const createPromise = () => {
  let resolver: (v: unknown) => void = noop
  let rejector: (v: unknown) => void = noop
  return [
    new Promise((resolve, reject) => {
      resolver = resolve
      rejector = reject
    }),
    resolver,
    rejector,
  ] as const
}

const useOpenModalWithPromise = () => {
  const [isOpen, setIsOpen] = useState(false)

  const [promise, resolve] = useMemo(() => createPromise(), [])
  const onOpen = () => {
    setIsOpen(true)
    return promise
  }
  const onClose = () => {
    setIsOpen(false)
    resolve(false)
  }

  return { promise, onOpen, onClose, isOpen } as const
}

const ProgressBarsList: FC<FlexProps & { data: ProgressBarData[]; onComplete: () => void }> = ({
  data,
  onComplete,
  ...props
}) => {
  const progress1 = useMotionValue(0)
  const progress2 = useMotionValue(0)
  const progress3 = useMotionValue(0)
  const {
    onOpen: openModal1,
    onClose: closeModal1,
    isOpen: isOpenModal1,
  } = useOpenModalWithPromise()
  const {
    onOpen: openModal2,
    onClose: closeModal2,
    isOpen: isOpenModal2,
  } = useOpenModalWithPromise()

  const DURATION1 = data[0]?.seconds_to_complete ?? DEFAULT_DURATION1
  const DURATION2 = data[1]?.seconds_to_complete ?? DEFAULT_DURATION2
  const DURATION3 = data[2]?.seconds_to_complete ?? DEFAULT_DURATION3
  useEffect(() => {
    const enterAnimation = async () => {
      await animate(progress1, STOP1, { duration: STOP1, ease: "easeIn" })
      await openModal1()
      await animate(progress1, 1, { duration: DURATION1 - STOP1, ease: "easeOut" })
      await animate(progress2, 1, { duration: DURATION2, ease: "easeInOut" })
      await animate(progress3, STOP2, { duration: STOP2, ease: "easeIn" })
      await openModal2()
      await animate(progress3, 1, { duration: DURATION3 - STOP2, ease: "easeOut" })
      onComplete()
      return
    }
    enterAnimation()
  }, [])

  return (
    <Flex direction="column" gap={2} {...props}>
      <QuestionModal
        title="Do you wear make-up on a daily basis?"
        onClose={closeModal1}
        isOpen={isOpenModal1}
      />
      <QuestionModal
        title="Have you tried face yoga before?"
        onClose={closeModal2}
        isOpen={isOpenModal2}
      />
      <ProgressBarItem title={data[0]?.title ?? "Analysing Skin Condition"} progress={progress1} />
      <ProgressBarItem title={data[1]?.title ?? "Reviewing your routine"} progress={progress2} />
      <ProgressBarItem
        title={data[2]?.title ?? "Generating your action plan"}
        progress={progress3}
      />
    </Flex>
  )
}

export type ProgressBarsPageProps = {
  title: AttributedStringType
  progressBars: ProgressBarData[]
  description: string | undefined
  children?: ReactNode
  next: NextPageCb
}

export const ProgressBarsWithQuestionsPage: FC<ProgressBarsPageProps> = ({
  title,
  description,
  next,
  progressBars,
}) => (
  <BasePageWrapper
    h="full"
    paddingX={6}
    paddingY={4}
    marginTop={12}
    direction="column"
    justifyContent="center"
  >
    <BasePageHeader marginBottom={8} title={title} subTitle={description} />
    <ProgressBarsList mb={8} onComplete={next} data={progressBars} />
    <Box textStyle="Subtitle/Secondary" textAlign="center" mb={3}>
      Trusted by over 28820 clients
    </Box>
    <ReviewsList
      list={reviews}
      w="calc(100% + 2 * var(--chakra-space-6))"
      marginX={-6}
      paddingX={6}
    />
  </BasePageWrapper>
)
