import { FC, useMemo } from "react"
import { NextPageCb } from "~/hooks/useNextQuiz"
import { Box, Flex, Heading, Text, chakra } from "@chakra-ui/react"
import {
  NextButton,
  NextButtonBottomSlideUpContainer as NextButtonContainer,
} from "~/components/shared/NextButton"
import { BasePageWrapper } from "~/components/shared/BasePageWrapper/BasePageWrapper"
import { TT, TTString } from "~/components/shared/AttributedString"
import { getResultsDate } from "~/components/shared/getResultDate"
import { RiveOrImage, RiveAlignment } from "~/components/shared/RiveImage"
import { useIsLoviTheme } from "~/theme/themeProvider"
import { CrossIcon } from "./Icons"

export const GraphPageGT: FC<{
  image: string
  next: NextPageCb
  weeksNumber: number
  prevWeeksNumber?: number
  description: TTString
}> = ({ image, next: onNext, prevWeeksNumber, description, weeksNumber }) => {
  const todayDateTitle = useMemo(() => getResultsDate(0), [])
  const finishDateTitle = useMemo(() => getResultsDate(weeksNumber), [weeksNumber])
  const isLoviTheme = useIsLoviTheme()

  return (
    <BasePageWrapper>
      <Heading textAlign={isLoviTheme ? "left" : "center"} mb={6} as="h1" size="Header/Primary">
        {!prevWeeksNumber ? (
          <>
            See visible changes
            <br />
            <chakra.em fontStyle="normal" textColor="Base/accentSecondary">
              in&nbsp;{weeksNumber}&nbsp;weeks
            </chakra.em>{" "}
            by {finishDateTitle}
          </>
        ) : (
          <>
            Based on your answers the results will come faster —{" "}
            <chakra.em fontStyle="normal" textColor="Base/accentSecondary">
              in&nbsp;
              <chakra.span pos="relative">
                {prevWeeksNumber}
                &nbsp;
                <CrossIcon left="-12px" top="-4px" pos="absolute" />
              </chakra.span>
              &nbsp;{weeksNumber}&nbsp;weeks
            </chakra.em>
          </>
        )}
      </Heading>
      <RiveOrImage width="full" src={image} ratio={1.7} alignment={RiveAlignment.BottomCenter} />
      <Flex mt={2} alignItems="center" justifyContent="space-between">
        <Box textStyle="Subtitle/Secondary" textColor="Base/basePrimary">
          {todayDateTitle} <chakra.span textColor="Base/baseDisabled">Today</chakra.span>
        </Box>
        <Box textStyle="Subtitle/Secondary" textColor="Base/accentSecondary">
          <chakra.span opacity={0.7}>in {weeksNumber} weeks</chakra.span> {finishDateTitle}
        </Box>
      </Flex>
      <Text
        mt={8}
        textAlign={isLoviTheme ? "left" : "center"}
        textStyle="Paragraph/Primary"
        textColor="Base/baseSecondary"
      >
        <TT>{description}</TT>
      </Text>
      <NextButtonContainer visible>
        <NextButton onClick={onNext} />
      </NextButtonContainer>
    </BasePageWrapper>
  )
}
